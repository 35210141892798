import * as d3 from 'd3'

export default {
  methods: {

    draw_Chart() {
      this.tableArea = this.svg
      .append('g')
      .attr('id', `Table_Area_${this.localId}`)
      .attr('transform', `translate(0, 0)`)
      .attr('font-family', 'roboto')

      let title = this.tableArea
      .append('g')
      .attr('id', `title_${this.localId}`)

      let overall = this.tableArea
      .append('g')
      .attr('id', `overall_${this.localId}`)

      let detail = this.tableArea
      .append('g')
      .attr('id', `detail_${this.localId}`)

      if (this.chartInfo.viewTitle == 'Y') {
        let x = 0
        let y = this.chartInfo.title.tSize

        if (this.chartInfo.title.tAnchor == 'end') {x = this.chartInfo.width}
        if (this.chartInfo.title.tAnchor == 'middle') {x = this.chartInfo.width / 2}

        title
        .append('text')
        .attr('x', x).attr('y', y)
        .attr('font-size', this.chartInfo.title.tSize)
        .attr('fill', this.chartInfo.title.tColor)
        .attr('text-anchor', this.chartInfo.title.tAnchor)
        .text(this.chartInfo.title.text)

        d3.select(`#overall_${this.localId}`).attr('transform', `translate(0, ${this.getNodeElValue(`#title_${this.localId}`, 'height') + 3})`)
        d3.select(`#detail_${this.localId}`).attr('transform', `translate(0, ${this.getNodeElValue(`#title_${this.localId}`, 'height') + 3})`)
      }

      if (this.chartInfo.viewOverall == 'Y') {
        this.draw_Overall(overall)

        let y = 0

        if (this.chartInfo.viewTitle == 'Y') {
          y = this.getNodeElValue(`#title_${this.localId}`, 'height') + this.getNodeElValue(`#overall_${this.localId}`, 'height') + 15
        } else {
          y = this.getNodeElValue(`#overall_${this.localId}`, 'height') + 12
        }
        
        d3.select(`#detail_${this.localId}`).attr('transform', `translate(0, ${y})`)
      }

      if (this.chartInfo.viewDetail == 'Y') {
        this.draw_Detail(detail)
      }
    },
    
  }
}