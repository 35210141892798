import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      let legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(${this.legends.x}, ${this.legends.y})`)
      .attr('font-family', 'roboto')

      legendGroup
      .append('text')
      .attr('transform', `translate(-10, ${this.legends.y-2})`)
      .style('font-size', 13).style('fill', '#000').attr('alignment-baseline', 'middle').attr('font-weight', 500)
      .text(`Legends`)

      legendGroup
      .append('text')
      .attr('transform', `translate(0, ${this.legends.y + 50})`)
      .style('font-size', 12).style('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Work Stage`)

      this.legend_Area(legendGroup) // ---> below
      this.legend_Stage(legendGroup) // ---> below
    },



    legend_Area(selection) {

      let x_ = 0
      this.areaCodes.forEach((d,i) => {
        
        let area = selection
        .append('g')
        .attr('id', `AREA_${d.status}`)
        .attr('transform', `translate(${parseInt(x_) + 10}, ${this.legends.y + 20})`)
        .attr('font-family', 'roboto')
        .attr('opacity', d.status == this.currentArea.status ? 1 : 0.8)
        .attr('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#AREA_${d.status}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#AREA_${d.status}`).attr('opacity', d == d ? 1 : 0.8) 
        })
        .on('click', ()=> {
          if(this.currentArea.text == d.text) return
          d3.select(`#AREA_${this.currentArea.status}`).attr('opacity', 0.8) 
          d3.select(`#AREA_text_${this.currentArea.status}`).attr('font-weight', 400).attr('fill', '#333')
          d3.select(`#AREA_in_${this.currentArea.status}`).attr('fill', '#fff')

          this.currentArea = this.areaCodes.find(f=> f.text == d.text)
          d3.select(`#AREA_${d.status}`).attr('opacity', 1) 
          d3.select(`#AREA_text_${d.status}`).attr('font-weight', 500).attr('fill', '#000')
          d3.select(`#AREA_in_${d.status}`).attr('fill', '#F35E90')

          this.set_Count(d.status) // ---> redrawing.js

          this.set_selectedItems() // ---> for PDF
        })
        
        area
        .append('circle')
        .attr('id', `AREA_Out_${d.status}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 6).attr('stroke', '#F7BACF').attr('stroke-width', 1).attr('fill', '#fff')

        area
        .append('circle')
        .attr('id', `AREA_in_${d.status}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 3).attr('stroke', '#F35E90').attr('stroke-width', 0).attr('fill', d.status == 'OVERALL' ? '#F35E90' : '#fff')

        area
        .append('text')
        .attr('id', `AREA_text_${d.status}`)
        .attr('transform', `translate(11, 0.5)`)
        .attr('font-size', 11)
        .attr('font-weight', this.currentArea.status == d.status ? 500 : 400)
        .attr('fill', '#333')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.text)

        x_ += this.getNodeElValue(`#AREA_text_${d.status}`, 'width') + 40
      })
    },



    legend_Stage(selection) {

      this.stageCodes.forEach((d, i) => {

        let stage = selection
        .append('g')
        .attr('id', `STAGE_${d.id}`)
        .attr('transform', `translate(${20+i*75}, 150)`)
        .attr('font-family', 'roboto')
        .attr('opacity', 1)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          if(this.currentStage.id == d.id) return
          d3.select(`#STAGE_${d.id}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#STAGE_${d.id}`).attr('opacity', 1) 
        })
        .on('click', ()=> {
          if(this.currentStage.id == d.id) return

          d3.select(`#STAGE_BOX_${this.currentStage.id}`).attr('opacity', 0)
          d3.select(`#STAGE_TEXT_${this.currentStage.id}`).attr('fill', '#333')
          d3.select(`#STAGE_TOT_${this.currentStage.id}`).attr('fill', '#757575')
          this.currentStage = this.stageCodes.find(f=> f.id == d.id) // #######

          d3.select(`#STAGE_BOX_${d.id}`).attr('opacity', 1)
          d3.select(`#STAGE_TEXT_${d.id}`).attr('fill', '#000')
          d3.select(`#STAGE_TOT_${this.currentStage.id}`).attr('fill', '#000')

          this.redraw_Timeline()
          
          this.set_selectedItems() // ---> for PDF
        })

        let height = this.legends.height
        let ongoing = ((d.ongoing+d.completed)/d.total) * height
        let completed = (d.completed/d.total) * height

        stage
        .append('rect')
        .attr('transform', `translate( 0, 0)`)
        .attr('rx', 3).attr('ry', 3).attr('width', 30).attr('height', height)
        .attr('fill', '#F4F5F5').attr('opacity', .75)

        stage
        .append('rect')
        .attr('id', `BAR_ONGOING_${d.id}`)
        .attr('transform', `translate( 0, ${height-ongoing})`)
        .attr('rx', 3).attr('ry', 3).attr('width', 30).attr('height', ongoing)
        .attr('opacity', 0.3)
        .attr('fill', d.color)

        stage
        .append('rect')
        .attr('id', `BAR_COMPLETED_${d.id}`)
        .attr('transform', `translate( 0, ${height-completed})`)
        .attr('rx', 3).attr('ry', 3).attr('width', 30).attr('height', completed)
        .attr('fill', d.color)

        stage
        .append('text') // TOTAL
        .attr('id', `STAGE_TOTAL_${d.id}`)
        .attr('transform', `translate(15, ${height+11})`)
        .attr('font-size', 11).attr('text-anchor', 'middle').attr('fill', this.currentStage.id == d.id ? '#000' : '#757575')
        .text(d.total)

        stage
        .append('text') // COMPLETED
        .attr('id', `STAGE_COMPLETED_${d.id}`)
        .attr('transform', `translate(15, ${height-completed})`)
        .attr('font-size', 11).attr('text-anchor', 'middle').attr('fill', '#333')
        .text(d.completed)

        stage
        .append('text') // ONGOING
        .attr('id', `STAGE_ONGOING_${d.id}`)
        .attr('transform', `translate(33, ${height-ongoing})`)
        .attr('font-size', 10).attr('text-anchor', 'start').attr('fill', '#bcbcbc')
        .text(d.ongoing)

        stage
        .append('text')
        .attr('id', `STAGE_TEXT_${d.id}`)
        .attr('transform', `translate(15, ${height+27})`)
        .attr('font-size', 10).attr('text-anchor', 'middle')
        .attr('fill', this.currentStage.id == d.id ? '#000' : '#333')
        .text(d.title)

        stage
        .append('text')
        .attr('id', `STAGE_SCH_${d.id}`)
        .attr('transform', `translate(15, ${height+40})`)
        .attr('font-size', 9).attr('text-anchor', 'middle')
        .attr('fill', '#757575')
        .text(d.sch)

        stage
        .append('rect')
        .attr('id', `STAGE_BOX_${d.id}`)
        .attr('transform', `translate( -10.5, ${height + 16.5})`)
        .attr('rx', 1).attr('ry', 1).attr('fill', 'none')
        .attr('width', 50).attr('height', 27)
        .attr('stroke', d.color).attr('stroke-width', 1)
        .attr('opacity', this.currentStage.id == d.id ? 1 : 0)

      })
      
    },


    
    
  }
}