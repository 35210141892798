
export default {
  data: () => ({

    localData: {
      
      // Erection List for 3D animation
      JSON1: [
        { SN: '1',   AREA: 'Hull',    LINK: '',  KP: 'KP-WN-S',   BLK: 'CC41', BLK5: 'CC41',  TITLE: 'Caission',                   X1: 528.33, Y1: 684.5,  X2: 507.18, Y2: 813.63, X3: 640.97,  Y3: 887.29, X4: 640.97,  Y4: 887.29, },
{ SN: '2',   AREA: 'Hull',    LINK: '',  KP: 'KP-WS-S',   BLK: 'CC42', BLK5: 'CC42',  TITLE: 'Caission',                   X1: 706.36, Y1: 607.01, X2: 919.4,  Y2: 600.86, X3: 1023.92, Y3: 662.12, X4: 1023.92, Y4: 662.12, },
{ SN: '3',   AREA: 'Hull',    LINK: '',  KP: 'KP-EN-S',   BLK: 'CC43', BLK5: 'CC43',  TITLE: 'Caission',                   X1: 383.06, Y1: 593.51, X2: 168.5,  Y2: 611.24, X3: 92,      Y3: 560.09, X4: 92,      Y4: 560.09, },
{ SN: '4',   AREA: 'Hull',    LINK: '',  KP: 'KP-ES-S',   BLK: 'CC44', BLK5: 'CC44',  TITLE: 'Caission',                   X1: 529.48, Y1: 496.08, X2: 548.93, Y2: 380.15, X3: 448.81,  Y3: 325.5,  X4: 448.81,  Y4: 325.5, },
{ SN: '5',   AREA: 'Hull',    LINK: 'Y', KP: 'KP-UCFC',   BLK: 'CF11', BLK5: 'CF111', TITLE: 'UCF CENTER',                 X1: 445.71, Y1: 528.02, X2: 421.66, Y2: 570.94, X3: 751.66,  Y3: 72,     X4: 751.66,  Y4: 72, },
{ SN: '6',   AREA: 'Hull',    LINK: 'Y', KP: 'KP-EN',     BLK: 'EC11', BLK5: 'EC112', TITLE: 'Lower Column (East-North)',  X1: 387.09, Y1: 640.78, X2: 172.53, Y2: 658.5,  X3: 172.53,  Y3: 595.16, X4: 172.53,  Y4: 595.16, },
{ SN: '7',   AREA: 'Hull',    LINK: 'Y', KP: 'KP-EN',     BLK: 'EC1G', BLK5: 'EC1G2', TITLE: 'Upper Column (East-North)',  X1: 387.09, Y1: 581.33, X2: 172.53, Y2: 599.05, X3: 172.53,  Y3: 409.39, X4: 172.53,  Y4: 409.39, },
{ SN: '8',   AREA: 'Hull',    LINK: 'Y', KP: 'KP-EN',     BLK: 'EC1K', BLK5: 'EC1K2', TITLE: 'Middle Column (East-North)', X1: 387.09, Y1: 602.2,  X2: 172.53, Y2: 619.93, X3: 172.53,  Y3: 494.33, X4: 172.53,  Y4: 494.33, },
{ SN: '9',   AREA: 'Hull',    LINK: 'Y', KP: 'KP-ES',     BLK: 'EC21', BLK5: 'EC212', TITLE: 'Lower Column (East-South)',  X1: 520.64, Y1: 560.64, X2: 540.09, Y2: 444.71, X3: 540.09,  Y3: 375.19, X4: 540.09,  Y4: 375.19, },
{ SN: '10',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-ES',     BLK: 'EC2G', BLK5: 'EC2G2', TITLE: 'Upper Column (East-South)',  X1: 527.75, Y1: 497.81, X2: 547.2,  Y2: 381.88, X3: 547.2,   Y3: 191.63, X4: 547.2,   Y4: 191.63, },
{ SN: '11',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-ES',     BLK: 'EC2K', BLK5: 'EC2K2', TITLE: 'Middle Column (East-South)', X1: 525.55, Y1: 519.87, X2: 545,    Y2: 403.94, X3: 545,     Y3: 277,    X4: 545,     Y4: 277, },
{ SN: '12',  AREA: 'Hull',    LINK: '',  KP: 'KP-EN',     BLK: 'EC3A', BLK5: 'EC3A',  TITLE: '',                           X1: 403.25, Y1: 672.32, X2: 188.69, Y2: 690.05, X3: 188.69,  Y3: 626.7,  X4: 158.93,  Y4: 643.73, },
{ SN: '13',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'EC4A', BLK5: 'EC4A',  TITLE: '',                           X1: 571.47, Y1: 562.53, X2: 590.92, Y2: 446.6,  X3: 590.92,  Y3: 377.08, X4: 610.92,  Y4: 365.08, },
{ SN: '14',  AREA: 'Hull',    LINK: '',  KP: 'KP-EN',     BLK: 'ECP1', BLK5: 'ECP1',  TITLE: 'Access P/F (East-North)',    X1: 441.94, Y1: 580.67, X2: 227.38, Y2: 598.4,  X3: 227.38,  Y3: 408.74, X4: 242.53,  Y4: 398.77, },
{ SN: '15',  AREA: 'Hull',    LINK: '',  KP: 'KP-EN',     BLK: 'ECP3', BLK5: 'ECP3',  TITLE: 'Access P/F (East-North)',    X1: 405,    Y1: 613,    X2: 190.44, Y2: 630.72, X3: 190.44,  Y3: 441.06, X4: 163.53,  Y4: 455.96, },
{ SN: '16',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'ECP4', BLK5: 'ECP4',  TITLE: 'Access P/F (East-South)',    X1: 533.74, Y1: 528.13, X2: 553.19, Y2: 412.2,  X3: 553.19,  Y3: 221.95, X4: 524.29,  Y4: 240.98, },
{ SN: '17',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'ECP5', BLK5: 'ECP5',  TITLE: 'Access P/F (East-South)',    X1: 522.99, Y1: 518.62, X2: 542.44, Y2: 402.69, X3: 542.44,  Y3: 212.44, X4: 522.51,  Y4: 220.7, },
{ SN: '18',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'ECP6', BLK5: 'ECP6',  TITLE: 'Access P/F (East-South)',    X1: 522.99, Y1: 493.6,  X2: 542.44, Y2: 377.67, X3: 542.44,  Y3: 187.42, X4: 529.22,  Y4: 182.73, },
{ SN: '19',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'ECP7', BLK5: 'ECP7',  TITLE: 'Access P/F (East-South)',    X1: 577.86, Y1: 503.5,  X2: 597.31, Y2: 387.58, X3: 597.31,  Y3: 197.33, X4: 611.31,  Y4: 190.33, },
{ SN: '20',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-UCFE',   BLK: 'EF21', BLK5: 'EF21',  TITLE: 'UCF East',                   X1: 435.16, Y1: 530.62, X2: 411.11, Y2: 573.53, X3: 336.78,  Y3: 293.8,  X4: 336.78,  Y4: 293.8, },
{ SN: '21',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-EN',     BLK: 'EN11', BLK5: 'EN112', TITLE: 'Node (East-North)',          X1: 387.09, Y1: 681.71, X2: 172.53, Y2: 699.44, X3: 172.53,  Y3: 699.44, X4: 172.53,  Y4: 699.44, },
{ SN: '22',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-ENC',    BLK: 'EN16', BLK5: 'EN161', TITLE: 'Diagonal Node (East-North)', X1: 444.46, Y1: 684.12, X2: 229.9,  Y2: 701.85, X3: 261.33,  Y3: 701.85, X4: 261.33,  Y4: 701.85, },
{ SN: '23',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-ES',     BLK: 'EN21', BLK5: 'EN212', TITLE: 'Node (East-South)',          X1: 523.63, Y1: 602.73, X2: 543.08, Y2: 486.8,  X3: 543.08,  Y3: 486.8,  X4: 543.08,  Y4: 486.8, },
{ SN: '24',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-ESC',    BLK: 'EN26', BLK5: 'EN261', TITLE: 'Diagonal Node (East-South)', X1: 528.87, Y1: 635.13, X2: 548.32, Y2: 519.2,  X3: 548.32,  Y3: 545.76, X4: 548.32,  Y4: 545.76, },
{ SN: '25',  AREA: 'Hull',    LINK: '',  KP: 'KP-EN',     BLK: 'EN41', BLK5: 'EN41',  TITLE: 'Push Knee',                  X1: 393.88, Y1: 672.67, X2: 179.32, Y2: 690.4,  X3: 179.32,  Y3: 690.4,  X4: 164.35,  Y4: 681.88, },
{ SN: '26',  AREA: 'Hull',    LINK: '',  KP: 'KP-EN',     BLK: 'EN42', BLK5: 'EN42',  TITLE: 'Push Knee',                  X1: 394.58, Y1: 699.14, X2: 180.02, Y2: 716.87, X3: 180.02,  Y3: 716.87, X4: 150.08,  Y4: 730.68, },
{ SN: '27',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'EN51', BLK5: 'EN51',  TITLE: 'Push Knee',                  X1: 532.68, Y1: 584.09, X2: 552.13, Y2: 468.16, X3: 552.13,  Y3: 468.16, X4: 540.07,  Y4: 461.65, },
{ SN: '28',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES',     BLK: 'EN52', BLK5: 'EN52',  TITLE: 'Push Knee',                  X1: 574.49, Y1: 588.1,  X2: 593.94, Y2: 472.17, X3: 593.94,  Y3: 472.17, X4: 606.62,  Y4: 465.63, },
{ SN: '29',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-EAST',   BLK: 'EP11', BLK5: 'EP112', TITLE: 'Pontoon (East)',             X1: 420.13, Y1: 620.53, X2: 321.47, Y2: 570.93, X3: 321.47,  Y3: 570.93, X4: 321.47,  Y4: 570.93, },
{ SN: '30',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-UCFN',   BLK: 'NF21', BLK5: 'NF21',  TITLE: 'UCF North',                  X1: 434.19, Y1: 606.59, X2: 410.14, Y2: 649.5,  X3: 323.85,  Y3: 495.9,  X4: 323.85,  Y4: 495.9, },
{ SN: '31',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-UCFN',   BLK: 'NFP1', BLK5: 'NFP1',  TITLE: 'UCF P/F (North)',            X1: 471.88, Y1: 619.68, X2: 447.83, Y2: 662.6,  X3: 361.53,  Y3: 509,    X4: 386.53,  Y4: 492, },
{ SN: '32',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-NORTH',  BLK: 'NP11', BLK5: 'NP112', TITLE: 'Pontoon (North)',            X1: 420.01, Y1: 708.9,  X2: 299.19, Y2: 780.12, X3: 299.19,  Y3: 780.12, X4: 299.19,  Y4: 780.12, },
{ SN: '33',  AREA: 'Hull',    LINK: '',  KP: 'KP-NORTH',  BLK: 'NPB1', BLK5: 'NPB1',  TITLE: '',                           X1: 468.98, Y1: 758.69, X2: 348.16, Y2: 829.91, X3: 348.16,  Y3: 829.91, X4: 319.52,  Y4: 844.04, },
{ SN: '34',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN-S',   BLK: 'RC41', BLK5: 'RC41',  TITLE: 'Riser Pipe',                 X1: 469.32, Y1: 671.44, X2: 448.17, Y2: 800.57, X3: 448.17,  Y3: 976.7,  X4: 448.17,  Y4: 976.7, },
{ SN: '35',  AREA: 'Hull',    LINK: '',  KP: 'KP-WS-S',   BLK: 'RC42', BLK5: 'RC42',  TITLE: 'Riser Pipe',                 X1: 612.34, Y1: 610.29, X2: 821.65, Y2: 613.65, X3: 997.22,  Y3: 700.36, X4: 997.22,  Y4: 700.36, },
{ SN: '36',  AREA: 'Hull',    LINK: '',  KP: 'KP-EN-S',   BLK: 'SC41', BLK5: 'SC41',  TITLE: 'Sump Pile',                  X1: 446.29, Y1: 586.77, X2: 231.73, Y2: 604.49, X3: 274.68,  Y3: 577,    X4: 274.68,  Y4: 577, },
{ SN: '37',  AREA: 'Hull',    LINK: '',  KP: 'KP-ES-S',   BLK: 'SC42', BLK5: 'SC42',  TITLE: 'Sump Pile',                  X1: 540.92, Y1: 530.37, X2: 560.37, Y2: 414.44, X3: 496.25,  Y3: 448.38, X4: 496.25,  Y4: 448.38, },
{ SN: '38',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-UCFS',   BLK: 'SF21', BLK5: 'SF21',  TITLE: 'UCF South',                  X1: 580.2,  Y1: 524.98, X2: 556.15, Y2: 567.89, X3: 709.44,  Y3: 282.75, X4: 709.44,  Y4: 282.75, },
{ SN: '39',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-SOUTH',  BLK: 'SP11', BLK5: 'SP112', TITLE: 'Pontoon (South)',            X1: 570.06, Y1: 620.24, X2: 689.02, Y2: 560.56, X3: 689.02,  Y3: 560.56, X4: 689.02,  Y4: 560.56, },
{ SN: '40',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WN',     BLK: 'WC11', BLK5: 'WC112', TITLE: 'Lower Column (West-North)',  X1: 521.1,  Y1: 719.64, X2: 499.96, Y2: 848.77, X3: 499.96,  Y3: 764.33, X4: 499.96,  Y4: 764.33, },
{ SN: '41',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WN',     BLK: 'WC1G', BLK5: 'WC1G2', TITLE: 'Upper Column (West-North)',  X1: 529.15, Y1: 661.75, X2: 508,    Y2: 790.88, X3: 508,     Y3: 599.75, X4: 508,     Y4: 599.75, },
{ SN: '42',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WN',     BLK: 'WC1K', BLK5: 'WC1K3', TITLE: 'Middle Column (West-North)', X1: 523.72, Y1: 682.66, X2: 502.58, Y2: 811.79, X3: 502.58,  Y3: 675.64, X4: 502.58,  Y4: 675.64, },
{ SN: '43',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WS',     BLK: 'WC21', BLK5: 'WC212', TITLE: 'Lower Column (West-South)',  X1: 667.09, Y1: 641.73, X2: 880.14, Y2: 635.58, X3: 880.14,  Y3: 573.52, X4: 880.14,  Y4: 573.52, },
{ SN: '44',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WS',     BLK: 'WC2G', BLK5: 'WC2G2', TITLE: 'Upper Column (West-South)',  X1: 665.67, Y1: 579.72, X2: 878.71, Y2: 573.58, X3: 878.71,  Y3: 383.68, X4: 878.71,  Y4: 383.68, },
{ SN: '45',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WS',     BLK: 'WC2K', BLK5: 'WC2K2', TITLE: 'Middle Column (West-South)', X1: 667.09, Y1: 600.75, X2: 880.13, Y2: 594.6,  X3: 880.13,  Y3: 469.81, X4: 880.13,  Y4: 469.81, },
{ SN: '46',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WC3A', BLK5: 'WC3A',  TITLE: '',                           X1: 580.35, Y1: 744.13, X2: 559.21, Y2: 873.26, X3: 559.21,  Y3: 788.82, X4: 587.73,  Y4: 805.3, },
{ SN: '47',  AREA: 'Hull',    LINK: '',  KP: 'KP-WS',     BLK: 'WC4A', BLK5: 'WC4A',  TITLE: '',                           X1: 704.79, Y1: 676.23, X2: 917.83, Y2: 670.08, X3: 917.83,  Y3: 608.01, X4: 950.61,  Y4: 626.69, },
{ SN: '48',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WCP1', BLK5: 'WCP1',  TITLE: 'Access P/F (West-North)',    X1: 577.8,  Y1: 680.78, X2: 556.66, Y2: 809.91, X3: 556.66,  Y3: 618.78, X4: 577.3,   Y4: 632.26, },
{ SN: '49',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WCP2', BLK5: 'WCP2',  TITLE: 'Access P/F (West-North)',    X1: 579.9,  Y1: 660.28, X2: 558.75, Y2: 789.41, X3: 558.75,  Y3: 598.28, X4: 577.91,  Y4: 593.44, },
{ SN: '50',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WCP3', BLK5: 'WCP3',  TITLE: 'Access P/F (West-North)',    X1: 568.03, Y1: 654.75, X2: 546.88, Y2: 783.88, X3: 546.88,  Y3: 592.75, X4: 558.59,  Y4: 582.43, },
{ SN: '51',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WCP4', BLK5: 'WCP4',  TITLE: 'Access P/F (West-North)',    X1: 522.98, Y1: 656.13, X2: 501.84, Y2: 785.26, X3: 501.84,  Y3: 594.13, X4: 481.77,  Y4: 580.39, },
{ SN: '52',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WCP5', BLK5: 'WCP5',  TITLE: 'Access P/F (West-North)',    X1: 511.11, Y1: 659.89, X2: 489.97, Y2: 789.02, X3: 489.97,  Y3: 597.89, X4: 467.58,  Y4: 591.51, },
{ SN: '53',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WCP6', BLK5: 'WCP6',  TITLE: 'Access P/F (West-North)',    X1: 524.39, Y1: 680.3,  X2: 503.24, Y2: 809.43, X3: 503.24,  Y3: 618.3,  X4: 484.52,  Y4: 631.57, },
{ SN: '54',  AREA: 'Hull',    LINK: '',  KP: 'KP-WS',     BLK: 'WCP8', BLK5: 'WCP8',  TITLE: 'Access P/F (West-South)',    X1: 700.41, Y1: 602.34, X2: 913.45, Y2: 596.19, X3: 913.45,  Y3: 406.3,  X4: 940.42,  Y4: 421.46, },
{ SN: '55',  AREA: 'Hull',    LINK: '',  KP: 'KP-WS',     BLK: 'WCP9', BLK5: 'WCP9',  TITLE: 'Access P/F (West-South)',    X1: 709.85, Y1: 579.72, X2: 922.89, Y2: 573.58, X3: 925.89,  Y3: 385.58, X4: 939.89,  Y4: 378.58, },
{ SN: '56',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-UCFW',   BLK: 'WF21', BLK5: 'WF21',  TITLE: 'UCF West',                   X1: 578.21, Y1: 607.87, X2: 554.17, Y2: 650.78, X3: 686.26,  Y3: 474.05, X4: 686.26,  Y4: 474.05, },
{ SN: '57',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WN',     BLK: 'WN11', BLK5: 'WN112', TITLE: 'Node (West-North)',          X1: 522.96, Y1: 765.46, X2: 501.81, Y2: 894.59, X3: 501.81,  Y3: 894.59, X4: 501.81,  Y4: 894.59, },
{ SN: '58',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WNC',    BLK: 'WN16', BLK5: 'WN161', TITLE: 'Diagonal Node (West-North)', X1: 527.25, Y1: 756.28, X2: 506.11, Y2: 885.41, X3: 506.11,  Y3: 859.96, X4: 506.11,  Y4: 859.96, },
{ SN: '59',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WS',     BLK: 'WN21', BLK5: 'WN212', TITLE: 'node (West-South)',          X1: 667.31, Y1: 682.13, X2: 880.36, Y2: 675.99, X3: 880.36,  Y3: 675.99, X4: 880.36,  Y4: 675.99, },
{ SN: '60',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WSC',    BLK: 'WN26', BLK5: 'WN261', TITLE: 'Diagonal Node (West-South)', X1: 657.16, Y1: 685.7,  X2: 870.2,  Y2: 679.55, X3: 844.75,  Y3: 679.55, X4: 844.75,  Y4: 679.55, },
{ SN: '63',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WN41', BLK5: 'WN41',  TITLE: 'Push Knee',                  X1: 575.26, Y1: 777.21, X2: 554.11, Y2: 906.34, X3: 554.11,  Y3: 906.34, X4: 579.45,  Y4: 921.36, },
{ SN: '64',  AREA: 'Hull',    LINK: '',  KP: 'KP-WN',     BLK: 'WN42', BLK5: 'WN42',  TITLE: 'Push Knee',                  X1: 529.29, Y1: 777.9,  X2: 508.14, Y2: 907.03, X3: 508.14,  Y3: 907.03, X4: 481.05,  Y4: 917.68, },
{ SN: '61',  AREA: 'Hull',    LINK: '',  KP: 'KP-WS',     BLK: 'WN51', BLK5: 'WN51',  TITLE: 'Push Knee',                  X1: 709.37, Y1: 697.77, X2: 922.42, Y2: 691.62, X3: 922.42,  Y3: 691.62, X4: 947.59,  Y4: 705.3, },
{ SN: '62',  AREA: 'Hull',    LINK: '',  KP: 'KP-WS',     BLK: 'WN52', BLK5: 'WN52',  TITLE: 'Push Knee',                  X1: 712.19, Y1: 673.05, X2: 925.24, Y2: 666.9,  X3: 925.24,  Y3: 666.9,  X4: 937.69,  Y4: 660.65, },
{ SN: '65',  AREA: 'Hull',    LINK: 'Y', KP: 'KP-WEST',   BLK: 'WP11', BLK5: 'WP112', TITLE: 'Pontoon (West)',             X1: 571.14, Y1: 708.63, X2: 666.98, Y2: 769.67, X3: 666.98,  Y3: 769.67, X4: 666.98,  Y4: 769.67, },
{ SN: '66',  AREA: 'Hull',    LINK: '',  KP: 'KP-WEST',   BLK: 'WPB1', BLK5: 'WPB1',  TITLE: 'Porch Basket',               X1: 608.14, Y1: 766,    X2: 703.98, Y2: 827.04, X3: 703.98,  Y3: 827.04, X4: 735.91,  Y4: 841.4, },
{ SN: '67',  AREA: 'Topside', LINK: 'Y', KP: 'KP-CD11',   BLK: 'CD11', BLK5: 'CD11',  TITLE: 'Topside Module (Central)',   X1: 520.08, Y1: 373.31, X2: 462.74, Y2: 422.18, X3: 462.74,  Y3: 422.18, X4: 0,        Y4: 0, },
{ SN: '68',  AREA: 'LQ',      LINK: 'Y', KP: 'KP-LD11',   BLK: 'LD11', BLK5: 'LD11',  TITLE: 'Living Qauter',              X1: 567.41, Y1: 531.27, X2: 567.41, Y2: 531.27, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
{ SN: '69',  AREA: 'LQ',      LINK: '',  KP: 'KP-LH11',   BLK: 'LH11', BLK5: 'LH11',  TITLE: 'Heli Deck',                  X1: 599.5,  Y1: 510.09, X2: 599.5,  Y2: 423.64, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
{ SN: '70',  AREA: 'LQ',      LINK: '',  KP: 'KP-LV51',   BLK: 'LV51', BLK5: 'LV51',  TITLE: 'Stair House',                X1: 700.99, Y1: 589.05, X2: 700.99, Y2: 534.59, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
{ SN: '71',  AREA: 'LQ',      LINK: '',  KP: 'KP-LV52',   BLK: 'LV52', BLK5: 'LV52',  TITLE: 'Stair',                      X1: 593.94, Y1: 551.67, X2: 593.94, Y2: 496.7,  X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
{ SN: '72',  AREA: 'LQ',      LINK: '',  KP: 'KP-LV53',   BLK: 'LV53', BLK5: 'LV53',  TITLE: 'Platform',                   X1: 638.65, Y1: 565.05, X2: 638.65, Y2: 504.2,  X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
{ SN: '73',  AREA: 'Topside', LINK: 'Y', KP: 'KP-ND11',   BLK: 'ND11', BLK5: 'ND11',  TITLE: 'Topside Module (North)',     X1: 449.49, Y1: 521.02, X2: 236.9,  Y2: 644.39, X3: 236.9,   Y3: 644.39, X4: 0,        Y4: 0, },
{ SN: '74',  AREA: 'Topside', LINK: 'Y', KP: 'KP-NF11',   BLK: 'NF11', BLK5: 'NF11',  TITLE: 'Flare Tower',                X1: 327.92, Y1: 478.88, X2: 115.33, Y2: 602.25, X3: 115.33,  Y3: 482.66, X4: 0,        Y4: 0, },
{ SN: '75',  AREA: 'Topside', LINK: 'T', KP: 'KP-SD11',   BLK: 'SD11', BLK5: 'SD11',  TITLE: 'Topside Module (South)',     X1: 614.73, Y1: 420.1,  X2: 678.79, Y2: 385.65, X3: 678.79,  Y3: 385.65, X4: 0,        Y4: 0, },
{ SN: '76',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-B', BLK: 'KD11', BLK5: 'KD11',  TITLE: 'Compressor Platform',        X1: 492.05, Y1: 530.34, X2: 491.19, Y2: 406.81, X3: 511.35,  Y3: 288.4,  X4: 0,        Y4: 0, },
{ SN: '77',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-B', BLK: 'ND11', BLK5: 'ND112', TITLE: 'Production Deck',            X1: 474.54, Y1: 577.97, X2: 474.54, Y2: 703.28, X3: 494.78,  Y3: 714.74, X4: 0,        Y4: 0, },
{ SN: '78',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-T', BLK: 'ND12', BLK5: 'ND12',  TITLE: 'Production Deck',            X1: 274.46, Y1: 491.76, X2: 274.46, Y2: 617.07, X3: 274.47,  Y3: 617.08, X4: 0,        Y4: 0, },
{ SN: '79',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-B', BLK: 'ND21', BLK5: 'ND21',  TITLE: 'Mezz. Deck',                 X1: 568.71, Y1: 637.36, X2: 569.06, Y2: 612.9,  X3: 601.64,  Y3: 621.03, X4: 0,        Y4: 0, },
{ SN: '80',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-B', BLK: 'ND22', BLK5: 'ND22',  TITLE: 'Mezz. Deck',                 X1: 521.87, Y1: 610.29, X2: 522.23, Y2: 585.82, X3: 541.6,   Y3: 585.9,  X4: 0,        Y4: 0, },
{ SN: '81',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-T', BLK: 'ND23', BLK5: 'ND23',  TITLE: 'Mezz. Deck',                 X1: 343.77, Y1: 547.97, X2: 344.13, Y2: 523.29, X3: 345.94,  Y3: 533.7,  X4: 0,        Y4: 0, },
{ SN: '82',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-T', BLK: 'ND25', BLK5: 'ND25',  TITLE: 'Mezz. Deck',                 X1: 432.26, Y1: 614.75, X2: 432.47, Y2: 590.06, X3: 419.64,  Y3: 609.42, X4: 0,        Y4: 0, },
{ SN: '83',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-B', BLK: 'ND31', BLK5: 'ND31',  TITLE: 'Main Deck',                  X1: 437.2,  Y1: 544.04, X2: 437.37, Y2: 421.85, X3: 458.66,  Y3: 433.66, X4: 0,        Y4: 0, },
{ SN: '84',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-T', BLK: 'ND33', BLK5: 'ND33',  TITLE: 'Main Deck',                  X1: 319.57, Y1: 477.74, X2: 320.15, Y2: 356.75, X3: 320.54,  Y3: 355.9,  X4: 0,        Y4: 0, },
{ SN: '85',  AREA: 'ND11',    LINK: 'Y', KP: 'KP-ND11-S', BLK: 'NX11', BLK5: 'NX11',  TITLE: 'Stair Tower',                X1: 462.18, Y1: 596.63, X2: 459.58, Y2: 721.48, X3: 378.54,  Y3: 784.06, X4: 0,        Y4: 0, },
{ SN: '86',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-T', BLK: 'ER31', BLK5: 'ER31',  TITLE: 'Erectrical Building',        X1: 365.74, Y1: 533.42, X2: 365.41, Y2: 468.72, X3: 362.07,  Y3: 258.08, X4: 0,        Y4: 0, },
{ SN: '87',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-B', BLK: 'GD11', BLK5: 'GD11',  TITLE: 'Generator Building',         X1: 428.18, Y1: 577.4,  X2: 428.18, Y2: 512.57, X3: 450.33,  Y3: 385.02, X4: 0,        Y4: 0, },
{ SN: '88',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-B', BLK: 'SD11', BLK5: 'SD11',  TITLE: 'Production Deck',            X1: 446.26, Y1: 639.61, X2: 446.26, Y2: 732.41, X3: 468.38,  Y3: 745.8,  X4: 0,        Y4: 0, },
{ SN: '89',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-T', BLK: 'SD12', BLK5: 'SD12',  TITLE: 'Production Deck',            X1: 267.92, Y1: 553.57, X2: 267.92, Y2: 646.38, X3: 267.74,  Y3: 647.09, X4: 0,        Y4: 0, },
{ SN: '90',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-B', BLK: 'SD31', BLK5: 'SD31',  TITLE: 'Main Deck',                  X1: 408.37, Y1: 608.01, X2: 408.15, Y2: 543.55, X3: 430.44,  Y3: 557.67, X4: 0,        Y4: 0, },
{ SN: '91',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-T', BLK: 'SD33', BLK5: 'SD33',  TITLE: 'Main Deck',                  X1: 295.8,  Y1: 543.8,  X2: 296.03, Y2: 478.87, X3: 297.28,  Y3: 479.88, X4: 0,        Y4: 0, },
{ SN: '92',  AREA: 'SD11',    LINK: 'Y', KP: 'KP-SD11-S', BLK: 'SX11', BLK5: 'SX11',  TITLE: 'Stair Tower',                X1: 587.1,  Y1: 632.26, X2: 587.1,  Y2: 725.06, X3: 660.22,  Y3: 705.39, X4: 0,        Y4: 0, },
{ SN: '93',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CD11', BLK5: 'CD11',  TITLE: 'Production Deck',            X1: 422.01, Y1: 618.14, X2: 422.01, Y2: 786.14, X3: 454.19,  Y3: 803.73, X4: 0,        Y4: 0, },
{ SN: '94',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-T', BLK: 'CD13', BLK5: 'CD13',  TITLE: 'Production Deck',            X1: 271.8,  Y1: 535.15, X2: 271.8,  Y2: 701.64, X3: 271.26,  Y3: 701.23, X4: 0,        Y4: 0, },
{ SN: '95',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CD21', BLK5: 'CD21',  TITLE: 'Mezz. Deck',                 X1: 528.08, Y1: 706.16, X2: 528.18, Y2: 685.03, X3: 562.6,   Y3: 708.74, X4: 0,        Y4: 0, },
{ SN: '96',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CD22', BLK5: 'CD22',  TITLE: 'Mezz. Deck',                 X1: 469.75, Y1: 673.16, X2: 469.75, Y2: 652.15, X3: 482.38,  Y3: 662.24, X4: 0,        Y4: 0, },
{ SN: '97',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CD23', BLK5: 'CD23',  TITLE: 'Mezz. Deck',                 X1: 576.92, Y1: 682.55, X2: 578,    Y2: 661.15, X3: 628.43,  Y3: 675.34, X4: 0,        Y4: 0, },
{ SN: '98',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CD24', BLK5: 'CD24',  TITLE: 'Mezz. Deck',                 X1: 503.24, Y1: 624.67, X2: 503.24, Y2: 603.66, X3: 531.79,  Y3: 604.65, X4: 0,        Y4: 0, },
{ SN: '99',  AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-T', BLK: 'CD25', BLK5: 'CD25',  TITLE: 'Mezz. Deck',                 X1: 349.7,  Y1: 616.16, X2: 349.7,  Y2: 595.15, X3: 341.43,  Y3: 593.16, X4: 0,        Y4: 0, },
{ SN: '100', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-T', BLK: 'CD26', BLK5: 'CD26',  TITLE: 'Mezz. Deck',                 X1: 466.78, Y1: 662.51, X2: 466.78, Y2: 641.51, X3: 458.28,  Y3: 639.51, X4: 0,        Y4: 0, },
{ SN: '101', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-T', BLK: 'CD27', BLK5: 'CD27',  TITLE: 'Mezz. Deck',                 X1: 495.35, Y1: 646.16, X2: 495.35, Y2: 625.15, X3: 502.86,  Y3: 614.32, X4: 0,        Y4: 0, },
{ SN: '102', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CD31', BLK5: 'CD31',  TITLE: 'Main Deck',                  X1: 417.53, Y1: 581.28, X2: 415.86, Y2: 418.6,  X3: 448.33,  Y3: 433.37, X4: 0,        Y4: 0, },
{ SN: '103', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-T', BLK: 'CD34', BLK5: 'CD34',  TITLE: 'Main Deck',                  X1: 292.94, Y1: 522.17, X2: 292.94, Y2: 358.66, X3: 294.35,  Y3: 355.06, X4: 0,        Y4: 0, },
{ SN: '104', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-B', BLK: 'CK11', BLK5: 'CK11',  TITLE: 'Pedestal',                   X1: 526.87, Y1: 655.14, X2: 526.87, Y2: 491.27, X3: 546.05,  Y3: 323.97, X4: 0,        Y4: 0, },
{ SN: '105', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-T', BLK: 'CK14', BLK5: 'CK14',  TITLE: 'Pedestal',                   X1: 292.68, Y1: 486.78, X2: 292.68, Y2: 322.91, X3: 311.85,  Y3: 239.44, X4: 0,        Y4: 0, },
{ SN: '106', AREA: 'CD11',    LINK: 'Y', KP: 'KP-CD11-S', BLK: 'CX11', BLK5: 'CX11',  TITLE: 'Stair Tower',                X1: 298.92, Y1: 508.72, X2: 298.92, Y2: 674.85, X3: 245.17,  Y3: 641.52, X4: 0,        Y4: 0, },
{ SN: '107', AREA: 'LD11',    LINK: 'Y', KP: 'KP-LD11',   BLK: 'LD11', BLK5: 'LD11',  TITLE: 'Level-1 Deck',               X1: 352.87, Y1: 523.04, X2: 352.87, Y2: 728.2,  X3: 352.87,  Y3: 728.2,  X4: 0,        Y4: 0, },
{ SN: '108', AREA: 'LD11',    LINK: 'Y', KP: 'KP-LD11',   BLK: 'LD21', BLK5: 'LD21',  TITLE: 'Level-2 Deck',               X1: 337.67, Y1: 500.68, X2: 337.67, Y2: 584.55, X3: 337.67,  Y3: 584.55, X4: 0,        Y4: 0, },
{ SN: '109', AREA: 'LD11',    LINK: 'Y', KP: 'KP-LD11',   BLK: 'LD31', BLK5: 'LD31',  TITLE: 'Level-3 Deck',               X1: 359.93, Y1: 500.48, X2: 359.93, Y2: 450.06, X3: 359.93,  Y3: 450.06, X4: 0,        Y4: 0, },
{ SN: '110', AREA: 'LD11',    LINK: 'Y', KP: 'KP-LD11',   BLK: 'LD41', BLK5: 'LD41',  TITLE: 'Level-4 Deck',               X1: 358.68, Y1: 495.93, X2: 358.68, Y2: 327.93, X3: 358.68,  Y3: 327.93, X4: 0,        Y4: 0, },
{ SN: '111', AREA: 'LD11',    LINK: 'Y', KP: 'KP-LD11',   BLK: 'LHS1', BLK5: 'LHS1',  TITLE: 'Heli Deck Support',          X1: 400.69, Y1: 470.7,  X2: 400.69, Y2: 302.7,  X3: 440.56,  Y3: 278.7,  X4: 0,        Y4: 0, },

      ],

    }

  }),
}