import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData            from '../primitives/localData'

import lmx_Events               from './Events'
import lmx_Watching             from './Watching'


import lmx_LocalValues          from './set_LocalValues'
import lmx_draw_Forms           from './draw_Forms'
import lmx_draw_PDF             from './draw_PDF'

import lmx_draw_Timeline        from './draw_Timeline'
import lmx_draw_Skyline         from './draw_Skyline'
import lmx_draw_Excepts         from './draw_Excepts'

import lmx_draw_Legends         from './draw_Legends'
import lmx_draw_Detail          from './draw_Detail'

import lmx_Redrawing            from './redrawing'



export default {
  
  mixins: [
    gmx_zMixins, 
    lmx_localData,

    // Local Mixins
    lmx_Events,
    lmx_Watching,
    
    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_PDF,

    lmx_draw_Timeline,
    lmx_draw_Skyline,
    lmx_draw_Excepts,
    
    lmx_draw_Legends,
    lmx_draw_Detail,
    lmx_Redrawing,

  ],

}
