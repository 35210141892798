
import * as d3 from 'd3'

export default {

  methods: {

    draw_Forms() {

      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
     
      if(this.selectMenu.AREA == 'OVERALL') {
        this.canvas_Form
        .append('rect')
        .attr('x', 0).attr('y', 1590).attr('width', 1300).attr('height', 10)
        .attr('fill', '#D71638')
      } else {
        this.canvas_Form
        .append('rect')
        .attr('x', 0).attr('y', 1110).attr('width', 1600).attr('height', 10)
        .attr('fill', '#D71638')
      }
   
      this.canvas_Form
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg')
      .attr('x', 30).attr('y', 30).attr('width', 230)


      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 108)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text(`Erection Network Diagram for ${this.selectMenu.AREA}`)

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 125)`)
      .attr('font-size', 12)
      .attr('fill', '#44A9DF')
      .text(this.selectMenu.SOURCE)
      
      this.loadSvg(
        this.canvas_Form, {
        x: 0, y: 0,
        url : `/Visualization/ErectionNetwork/Network_${this.selectMenu.AREA}.svg`
      }).then(() => {

        d3.selectAll("text").style('font-family', 'roboto').style('cursor', 'default')

        if(this.selectMenu.AREA == 'OVERALL') {
          
          //OVERALL
          this.Erection_List.forEach((d,i) => {
            d3.select(`#MASK-${d.ID}`).style('opacity', 0)
            .style('cursor', 'pointer')
            .on('mouseover', () => {
              d3.select(`#PNG-${d.ID}`).transition().duration(200).style('opacity', .5)
            })
            .on('mouseout', () => {
              d3.select(`#PNG-${d.ID}`).transition().duration(200).style('opacity', 1)
            })
            .on('click', () => {
              this.draw_Popup_Window(d)

              this.modalOpen = 'Y'
              this.modalId = d.ID
              this.set_selectedItems()
            }) 
          })

           // Load a JGS logo SVG
          this.loadSvg(
            this.canvas_Form, {
            x   : 1160, y   : 1540,
            url : '/icon/Logo/JGS.svg'
          })

          let overall = this.canvas_Form
          .append('g')
          .attr('transform', `translate(870,550)`)

          this.func_stageProgress_EL( // -----> ### vFunction/stage_Progress.js
            overall,
            {
              NAME: 'Construction',
              EP: this.selectMenu.CO_EP,
              LP: this.selectMenu.CO_LP,
              ACT: this.selectMenu.CO_A,
              ED: this.selectMenu.CO_A - this.selectMenu.CO_EP,
              LD: this.selectMenu.CO_A - this.selectMenu.CO_LP,
            }, 
            { id: 'ER', x: 0, y: 0, },
          ) 

          this.func_stageBarChart( // -----> ### vFunction/stage_BarChart.js
            overall, this.masterList, 
            {
              id: 'OVERALL',
              x: 0,       y: 30,     nameLen: 90,
              tSize: 9,     lineH: 16,
              barLen: 150,  barH: 11,
            },
          ) 

        } else {

          // HULL & TOPSIDE
          this.Erection_List.filter(f=> f.AREA == this.selectMenu.AREA).forEach((d,i) => {
            d3.select(`#BOX-${d.ID}`).attr('opacity', 1)
            d3.select(`#EREC-${d.ID}`)
            .style('cursor', 'pointer')
            .on('mouseover', () => {
              d3.select(`#BOX-${d.ID}`).transition().duration(200)
              .style('fill', '#F4F5F5').style('stroke', '#757575').attr('opacity', .5)
            })
            .on('mouseout', () => {
              d3.select(`#BOX-${d.ID}`).transition().duration(200)
              .style('fill', '#fff').style('stroke', '#EC417A').attr('opacity', 1)
            })
            .on('click', () => {
              this.draw_Popup_Window(d)

              this.modalOpen = 'Y'
              this.modalId = d.ID
              this.set_selectedItems()
            }) 
          })
        }
        this.func_exeBlockColoring(this.canvas_Form, this.blockColor, '') // -----> vFunction/block_Coloring.js
        
      })


    },


    

    

   

  }
}