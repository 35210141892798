/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/

// Special Charts
import TR_SCURVE_ENG            from '../10_Trion/Charts/SCurve_ENG/index.vue'
import TR_SCURVE_PRO            from '../10_Trion/Charts/SCurve_PRO/index.vue'
import TR_SCURVE_CON            from '../10_Trion/Charts/SCurve_CON/index.vue'
import TR_CONST_AREA            from '../10_Trion/Charts/Const_Area/index.vue'
import TR_CONST_STAGE           from '../10_Trion/Charts/Const_Stages/index.vue'

// Skyline Charts
import TR_SKYLINE_BLOCK         from '../10_Trion/Skylines/Blocks/index.vue'

// 3D Visualization
import TR_Erection_Network      from '../10_Trion/Visualization/Erection_Network/index.vue'
import TR_Erection_Timeline     from '../10_Trion/Visualization/Erection_Timeline/index.vue'
import TR_Block_Division        from '../10_Trion/Visualization/Block_Division/index.vue'
import TR_Layout_Equipment      from '../10_Trion/Visualization/Layout_Equipment/index.vue'
import TR_Layout_Yard           from '../10_Trion/Visualization/Layout_Yard/index.vue'

export default {
  
  // Special Charts
  TR_SCURVE_ENG,
  TR_SCURVE_PRO,
  TR_SCURVE_CON,
  TR_CONST_AREA,
  TR_CONST_STAGE,

  // Skyline Charts
  TR_SKYLINE_BLOCK,

  // 3D Visualization
  TR_Erection_Network,
  TR_Erection_Timeline,
  TR_Block_Division,
  TR_Layout_Equipment,
  TR_Layout_Yard,
}
