
export default {
  data: () => ({

    localData: {

      // stepData
      JSON1: [ 
        { SN: '1',  CDATE: '2025-01-31', },
        { SN: '2',  CDATE: '2025-02-28', },
        { SN: '3',  CDATE: '2025-03-28', },
        { SN: '4',  CDATE: '2025-04-25', },
        { SN: '5',  CDATE: '2025-05-30', },
        { SN: '6',  CDATE: '2025-06-27', },
        { SN: '7',  CDATE: '2025-07-25', },
        { SN: '8',  CDATE: '2025-08-29', },
        { SN: '9',  CDATE: '2025-09-26', },
        { SN: '10', CDATE: '2025-10-31', },
        { SN: '11', CDATE: '2025-11-28', },
        { SN: '12', CDATE: '2025-12-26', },
        { SN: '13', CDATE: '2026-01-30', },
        { SN: '14', CDATE: '2026-02-27', },
        { SN: '15', CDATE: '2026-03-27', },
        { SN: '16', CDATE: '2026-04-24', },
        { SN: '17', CDATE: '2026-05-29', },
        { SN: '18', CDATE: '2026-06-26', },
        { SN: '19', CDATE: '2026-07-31', },
        { SN: '20', CDATE: '2026-08-28', },
        { SN: '21', CDATE: '2026-09-25', },
        { SN: '22', CDATE: '2026-10-30', },
        { SN: '23', CDATE: '2026-11-27', },
        { SN: '24', CDATE: '2026-12-25', },
        { SN: '25', CDATE: '2027-01-29', },
        { SN: '26', CDATE: '2027-02-26', },
        { SN: '27', CDATE: '2027-03-26', },
      ],
  
      // BLOCKS
      JSON2: [ 
        { MONTH: '2025-02', BLK: 'EN11',    SVG: 'EN11',    BLK5: 'EN112', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node', },
        { MONTH: '2025-02', BLK: 'EP11',    SVG: 'EP11',    BLK5: 'EP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'East Pontoon', },
        { MONTH: '2025-02', BLK: 'LD11',    SVG: 'LD11',    BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 1', },
        { MONTH: '2025-03', BLK: 'EN21',    SVG: 'EN21',    BLK5: 'EN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-03', BLK: 'LD21',    SVG: 'LD21',    BLK5: 'LD21',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 2', },
        { MONTH: '2025-03', BLK: 'LD31',    SVG: 'LD31',    BLK5: 'LD31',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 3', },
        { MONTH: '2025-03', BLK: 'LD41',    SVG: 'LD41',    BLK5: 'LD41',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2025-03', BLK: 'WN11',    SVG: 'WN11',    BLK5: 'WN112', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node', },
        { MONTH: '2025-03', BLK: 'WN21',    SVG: 'WN21',    BLK5: 'WN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-04', BLK: 'CD11',    SVG: 'CD11',    BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck', },
        { MONTH: '2025-04', BLK: 'CD13',    SVG: 'CD13',    BLK5: 'CD13',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck', },
        { MONTH: '2025-04', BLK: 'EN16',    SVG: 'EN16',    BLK5: 'EN161', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node', },
        { MONTH: '2025-04', BLK: 'EN21',    SVG: 'EN21',    BLK5: 'EN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-04', BLK: 'LD11',    SVG: 'LD11',    BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 1', },
        { MONTH: '2025-04', BLK: 'NP11',    SVG: 'NP11',    BLK5: 'NP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon', },
        { MONTH: '2025-04', BLK: 'SD11',    SVG: 'SD11',    BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-04', BLK: 'SD12',    SVG: 'SD12',    BLK5: 'SD12',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-04', BLK: 'WN21',    SVG: 'WN21',    BLK5: 'WN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-05', BLK: 'CD21',    SVG: 'CD21',    BLK5: 'CD21',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-05', BLK: 'CD22',    SVG: 'CD22',    BLK5: 'CD22',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-05', BLK: 'CD23',    SVG: 'CD23',    BLK5: 'CD23',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-05', BLK: 'CD24',    SVG: 'CD24',    BLK5: 'CD24',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-05', BLK: 'EN11',    SVG: 'EN11',    BLK5: 'EN112', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node', },
        { MONTH: '2025-05', BLK: 'EN21',    SVG: 'EN21',    BLK5: 'EN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-05', BLK: 'EP11',    SVG: 'EP11',    BLK5: 'EP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'East Pontoon', },
        { MONTH: '2025-05', BLK: 'LD11',    SVG: 'LD11',    BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 1', },
        { MONTH: '2025-05', BLK: 'LD21',    SVG: 'LD21',    BLK5: 'LD21',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 2', },
        { MONTH: '2025-05', BLK: 'LD31',    SVG: 'LD31',    BLK5: 'LD31',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 3', },
        { MONTH: '2025-05', BLK: 'LD41',    SVG: 'LD41',    BLK5: 'LD41',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2025-05', BLK: 'NP11',    SVG: 'NP11',    BLK5: 'NP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon', },
        { MONTH: '2025-05', BLK: 'SD11',    SVG: 'SD11',    BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-05', BLK: 'SD12',    SVG: 'SD12',    BLK5: 'SD12',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-05', BLK: 'WN11',    SVG: 'WN11',    BLK5: 'WN112', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node', },
        { MONTH: '2025-05', BLK: 'WN21',    SVG: 'WN21',    BLK5: 'WN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-05', BLK: 'WN26',    SVG: 'WN26',    BLK5: 'WN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-06', BLK: 'CD22',    SVG: 'CD22',    BLK5: 'CD22',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'CD23',    SVG: 'CD23',    BLK5: 'CD23',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'CD24',    SVG: 'CD24',    BLK5: 'CD24',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'CD25',    SVG: 'CD25',    BLK5: 'CD25',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'CD31',    SVG: 'CD31',    BLK5: 'CD31',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-06', BLK: 'EN16',    SVG: 'EN16',    BLK5: 'EN161', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node', },
        { MONTH: '2025-06', BLK: 'EN21',    SVG: 'EN21',    BLK5: 'EN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-06', BLK: 'LD41',    SVG: 'LD41',    BLK5: 'LD41',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2025-06', BLK: 'LHS1',    SVG: 'LHS1',    BLK5: 'LHS1',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2025-06', BLK: 'Overall', SVG: 'LQ',      BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'Module', },
        { MONTH: '2025-06', BLK: 'ND11',    SVG: 'ND11',    BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-06', BLK: 'ND12',    SVG: 'ND12',    BLK5: 'ND12',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-06', BLK: 'ND21',    SVG: 'ND21',    BLK5: 'ND21',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'ND22',    SVG: 'ND22',    BLK5: 'ND22',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'ND23',    SVG: 'ND23',    BLK5: 'ND23',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-06', BLK: 'ND31',    SVG: 'ND31',    BLK5: 'ND31',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-06', BLK: 'NP11',    SVG: 'NP11',    BLK5: 'NP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon', },
        { MONTH: '2025-06', BLK: 'SP11',    SVG: 'SP11',    BLK5: 'SP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'South Pontoon', },
        { MONTH: '2025-06', BLK: 'WN11',    SVG: 'WN11',    BLK5: 'WN112', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node', },
        { MONTH: '2025-06', BLK: 'WN16',    SVG: 'WN16',    BLK5: 'WN161', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node', },
        { MONTH: '2025-06', BLK: 'WN26',    SVG: 'WN26',    BLK5: 'WN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-06', BLK: 'WP11',    SVG: 'WP11',    BLK5: 'WP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon', },
        { MONTH: '2025-07', BLK: 'CD13',    SVG: 'CD13',    BLK5: 'CD13',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck', },
        { MONTH: '2025-07', BLK: 'CD31',    SVG: 'CD31',    BLK5: 'CD31',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-07', BLK: 'CD34',    SVG: 'CD34',    BLK5: 'CD34',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-07', BLK: 'EC11',    SVG: 'EC11',    BLK5: 'EC112', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Lower Column', },
        { MONTH: '2025-07', BLK: 'EC1G',    SVG: 'EC1G',    BLK5: 'EC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column', },
        { MONTH: '2025-07', BLK: 'EC21',    SVG: 'EC21',    BLK5: 'EC212', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Lower Column', },
        { MONTH: '2025-07', BLK: 'EC2G',    SVG: 'EC2G',    BLK5: 'EC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Upper Column', },
        { MONTH: '2025-07', BLK: 'EN16',    SVG: 'EN16',    BLK5: 'EN161', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node', },
        { MONTH: '2025-07', BLK: 'EN26',    SVG: 'EN26',    BLK5: 'EN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-07', BLK: 'ER31',    SVG: 'ER31',    BLK5: 'ER31',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Erectrical Building', },
        { MONTH: '2025-07', BLK: 'Overall', SVG: 'LQ',      BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'Module', },
        { MONTH: '2025-07', BLK: 'ND21',    SVG: 'ND21',    BLK5: 'ND21',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-07', BLK: 'SD12',    SVG: 'SD12',    BLK5: 'SD12',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-07', BLK: 'SP11',    SVG: 'SP11',    BLK5: 'SP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'South Pontoon', },
        { MONTH: '2025-07', BLK: 'WC1G',    SVG: 'WC1G',    BLK5: 'WC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column', },
        { MONTH: '2025-07', BLK: 'WC2G',    SVG: 'WC2G',    BLK5: 'WC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column', },
        { MONTH: '2025-07', BLK: 'WN11',    SVG: 'WN11',    BLK5: 'WN112', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node', },
        { MONTH: '2025-07', BLK: 'WN21',    SVG: 'WN21',    BLK5: 'WN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-07', BLK: 'WP11',    SVG: 'WP11',    BLK5: 'WP112', AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon', },
        { MONTH: '2025-08', BLK: 'CD22',    SVG: 'CD22',    BLK5: 'CD22',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-08', BLK: 'CD23',    SVG: 'CD23',    BLK5: 'CD23',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-08', BLK: 'CD24',    SVG: 'CD24',    BLK5: 'CD24',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-08', BLK: 'CD34',    SVG: 'CD34',    BLK5: 'CD34',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-08', BLK: 'CD34',    SVG: 'CD34',    BLK5: 'CD34',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-08', BLK: 'EC11',    SVG: 'EC11',    BLK5: 'EC112', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Lower Column', },
        { MONTH: '2025-08', BLK: 'EC1G',    SVG: 'EC1G',    BLK5: 'EC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column', },
        { MONTH: '2025-08', BLK: 'EC21',    SVG: 'EC21',    BLK5: 'EC212', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Lower Column', },
        { MONTH: '2025-08', BLK: 'EC2G',    SVG: 'EC2G',    BLK5: 'EC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Upper Column', },
        { MONTH: '2025-08', BLK: 'EN26',    SVG: 'EN26',    BLK5: 'EN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-08', BLK: 'EN26',    SVG: 'EN26',    BLK5: 'EN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node', },
        { MONTH: '2025-08', BLK: 'ER31',    SVG: 'ER31',    BLK5: 'ER31',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Erectrical Building', },
        { MONTH: '2025-08', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2025-08', BLK: 'Overall', SVG: 'LQ',      BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'Module', },
        { MONTH: '2025-08', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2025-08', BLK: 'ND12',    SVG: 'ND12',    BLK5: 'ND12',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck', },
        { MONTH: '2025-08', BLK: 'ND22',    SVG: 'ND22',    BLK5: 'ND22',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-08', BLK: 'SD31',    SVG: 'SD31',    BLK5: 'SD31',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-08', BLK: 'SD33',    SVG: 'SD33',    BLK5: 'SD33',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-08', BLK: 'WC11',    SVG: 'WC11',    BLK5: 'WC112', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Lower Column', },
        { MONTH: '2025-08', BLK: 'WC1G',    SVG: 'WC1G',    BLK5: 'WC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column', },
        { MONTH: '2025-08', BLK: 'WC21',    SVG: 'WC21',    BLK5: 'WC212', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Lower Column', },
        { MONTH: '2025-08', BLK: 'WC2G',    SVG: 'WC2G',    BLK5: 'WC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column', },
        { MONTH: '2025-08', BLK: 'WN21',    SVG: 'WN21',    BLK5: 'WN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-08', BLK: 'WN26',    SVG: 'WN26',    BLK5: 'WN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-09', BLK: 'CD31',    SVG: 'CD31',    BLK5: 'CD31',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-09', BLK: 'CK11',    SVG: 'CK11',    BLK5: 'CK11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-09', BLK: 'EC11',    SVG: 'EC11',    BLK5: 'EC112', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Lower Column', },
        { MONTH: '2025-09', BLK: 'EC21',    SVG: 'EC21',    BLK5: 'EC212', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Lower Column', },
        { MONTH: '2025-09', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2025-09', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2025-09', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2025-09', BLK: 'ND21',    SVG: 'ND21',    BLK5: 'ND21',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-09', BLK: 'ND22',    SVG: 'ND22',    BLK5: 'ND22',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-09', BLK: 'ND23',    SVG: 'ND23',    BLK5: 'ND23',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-09', BLK: 'ND31',    SVG: 'ND31',    BLK5: 'ND31',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-09', BLK: 'ND33',    SVG: 'ND33',    BLK5: 'ND33',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-09', BLK: 'SD33',    SVG: 'SD33',    BLK5: 'SD33',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-09', BLK: 'WC11',    SVG: 'WC11',    BLK5: 'WC112', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Lower Column', },
        { MONTH: '2025-09', BLK: 'WC21',    SVG: 'WC21',    BLK5: 'WC212', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Lower Column', },
        { MONTH: '2025-09', BLK: 'WN21',    SVG: 'WN21',    BLK5: 'WN212', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-09', BLK: 'WPB1',    SVG: 'WPB1',    BLK5: 'WPB1',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon', },
        { MONTH: '2025-10', BLK: 'CD31',    SVG: 'CD31',    BLK5: 'CD31',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'CD34',    SVG: 'CD34',    BLK5: 'CD34',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'CK11',    SVG: 'CK11',    BLK5: 'CK11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'CK14',    SVG: 'CK14',    BLK5: 'CK14',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'EC1G',    SVG: 'EC1G',    BLK5: 'EC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column', },
        { MONTH: '2025-10', BLK: 'EC1K',    SVG: 'EC1K',    BLK5: 'EC1K2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Middle Column', },
        { MONTH: '2025-10', BLK: 'EC2G',    SVG: 'EC2G',    BLK5: 'EC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Upper Column', },
        { MONTH: '2025-10', BLK: 'EC2K',    SVG: 'EC2K',    BLK5: 'EC2K2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Middle Column', },
        { MONTH: '2025-10', BLK: 'EF21',    SVG: 'EF21',    BLK5: 'EF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'East', },
        { MONTH: '2025-10', BLK: 'EN42',    SVG: 'EN42',    BLK5: 'EN42',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-East', },
        { MONTH: '2025-10', BLK: 'EN52',    SVG: 'EN52',    BLK5: 'EN52',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-East', },
        { MONTH: '2025-10', BLK: 'ER31',    SVG: 'ER31',    BLK5: 'ER31',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Erectrical Building', },
        { MONTH: '2025-10', BLK: 'GD11',    SVG: 'GD11',    BLK5: 'GD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Generator Building', },
        { MONTH: '2025-10', BLK: 'KD11',    SVG: 'KD11',    BLK5: 'KD11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Compressor Platform', },
        { MONTH: '2025-10', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2025-10', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2025-10', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2025-10', BLK: 'ND31',    SVG: 'ND31',    BLK5: 'ND31',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'ND33',    SVG: 'ND33',    BLK5: 'ND33',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'SD31',    SVG: 'SD31',    BLK5: 'SD31',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-10', BLK: 'WC1G',    SVG: 'WC1G',    BLK5: 'WC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column', },
        { MONTH: '2025-10', BLK: 'WC1K',    SVG: 'WC1K',    BLK5: 'WC1K3', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Middle Column', },
        { MONTH: '2025-10', BLK: 'WC2G',    SVG: 'WC2G',    BLK5: 'WC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column', },
        { MONTH: '2025-10', BLK: 'WC2K',    SVG: 'WC2K',    BLK5: 'WC2K2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Middle Column', },
        { MONTH: '2025-10', BLK: 'WN26',    SVG: 'WN26',    BLK5: 'WN261', AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node', },
        { MONTH: '2025-10', BLK: 'WN41',    SVG: 'WN41',    BLK5: 'WN41',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-West', },
        { MONTH: '2025-10', BLK: 'WN42',    SVG: 'WN42',    BLK5: 'WN42',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-West', },
        { MONTH: '2025-11', BLK: 'CX11',    SVG: 'CX11',    BLK5: 'CX11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-11', BLK: 'EC1G',    SVG: 'EC1G',    BLK5: 'EC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column', },
        { MONTH: '2025-11', BLK: 'EC2G',    SVG: 'EC2G',    BLK5: 'EC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Upper Column', },
        { MONTH: '2025-11', BLK: 'EF21',    SVG: 'EF21',    BLK5: 'EF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'East', },
        { MONTH: '2025-11', BLK: 'ER31',    SVG: 'ER31',    BLK5: 'ER31',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Erectrical Building', },
        { MONTH: '2025-11', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2025-11', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2025-11', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2025-11', BLK: 'ND21',    SVG: 'ND21',    BLK5: 'ND21',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck', },
        { MONTH: '2025-11', BLK: 'SC41',    SVG: 'SC41',    BLK5: 'SC41',  AREA: 'HULL',    ZONE: 'SUMP PILE',  LEVEL: 'NE', },
        { MONTH: '2025-11', BLK: 'SD33',    SVG: 'SD33',    BLK5: 'SD33',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-11', BLK: 'SF21',    SVG: 'SF21',    BLK5: 'SF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'South', },
        { MONTH: '2025-11', BLK: 'WC1G',    SVG: 'WC1G',    BLK5: 'WC1G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column', },
        { MONTH: '2025-11', BLK: 'WC1K',    SVG: 'WC1K',    BLK5: 'WC1K3', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Middle Column', },
        { MONTH: '2025-11', BLK: 'WC2G',    SVG: 'WC2G',    BLK5: 'WC2G2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column', },
        { MONTH: '2025-11', BLK: 'WC2K',    SVG: 'WC2K',    BLK5: 'WC2K2', AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Middle Column', },
        { MONTH: '2025-11', BLK: 'WF21',    SVG: 'WF21',    BLK5: 'WF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'West', },
        { MONTH: '2025-11', BLK: 'WN51',    SVG: 'WN51',    BLK5: 'WN51',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-West', },
        { MONTH: '2025-12', BLK: 'CC41',    SVG: 'CC41',    BLK5: 'CC41',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'NW', },
        { MONTH: '2025-12', BLK: 'CC42',    SVG: 'CC42',    BLK5: 'CC42',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'SW', },
        { MONTH: '2025-12', BLK: 'CC44',    SVG: 'CC44',    BLK5: 'CC44',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'SE', },
        { MONTH: '2025-12', BLK: 'CF11',    SVG: 'CF11',    BLK5: 'CF111', AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'Center', },
        { MONTH: '2025-12', BLK: 'CK14',    SVG: 'CK14',    BLK5: 'CK14',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck', },
        { MONTH: '2025-12', BLK: 'GD11',    SVG: 'GD11',    BLK5: 'GD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Generator Building', },
        { MONTH: '2025-12', BLK: 'KD11',    SVG: 'KD11',    BLK5: 'KD11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Compressor Platform', },
        { MONTH: '2025-12', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2025-12', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2025-12', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2025-12', BLK: 'ND31',    SVG: 'ND31',    BLK5: 'ND31',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-12', BLK: 'NF21',    SVG: 'NF21',    BLK5: 'NF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'North', },
        { MONTH: '2025-12', BLK: 'NFP1',    SVG: 'NFP1',    BLK5: 'NFP1',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'North', },
        { MONTH: '2025-12', BLK: 'NX11',    SVG: 'NX11',    BLK5: 'NX11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck', },
        { MONTH: '2025-12', BLK: 'RC41',    SVG: 'RC41',    BLK5: 'RC41',  AREA: 'HULL',    ZONE: 'RISER PIPE', LEVEL: 'NW', },
        { MONTH: '2025-12', BLK: 'RC42',    SVG: 'RC42',    BLK5: 'RC42',  AREA: 'HULL',    ZONE: 'RISER PIPE', LEVEL: 'SW', },
        { MONTH: '2025-12', BLK: 'SC42',    SVG: 'SC42',    BLK5: 'SC42',  AREA: 'HULL',    ZONE: 'SUMP PILE',  LEVEL: 'SE', },
        { MONTH: '2025-12', BLK: 'SF21',    SVG: 'SF21',    BLK5: 'SF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'South', },
        { MONTH: '2025-12', BLK: 'WF21',    SVG: 'WF21',    BLK5: 'WF21',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'West', },
        { MONTH: '2026-01', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2026-01', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2026-01', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2026-02', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2026-02', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2026-02', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2026-03', BLK: 'Overall', SVG: 'CENTRAL', BLK5: 'CD11',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Module', },
        { MONTH: '2026-03', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2026-03', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2026-04', BLK: 'Overall', SVG: 'NORTH',   BLK5: 'ND11',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Module', },
        { MONTH: '2026-04', BLK: 'Overall', SVG: 'SOUTH',   BLK5: 'SD11',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Module', },
        { MONTH: '2026-05', BLK: 'LH11',    SVG: 'LH11',    BLK5: 'LH11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2026-05', BLK: 'LV51',    SVG: 'LV51',    BLK5: 'LV51',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2026-05', BLK: 'LV52',    SVG: 'LV52',    BLK5: 'LV52',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2026-05', BLK: 'LV53',    SVG: 'LV53',    BLK5: 'LV53',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2026-05', BLK: 'Overall', SVG: 'LQ',      BLK5: 'LD11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'Module', },
        { MONTH: '2026-06', BLK: 'LH11',    SVG: 'LH11',    BLK5: 'LH11',  AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4', },
        { MONTH: '2026-11', BLK: 'Overall', SVG: 'FLARE',   BLK5: 'NF111', AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Lower', },
        { MONTH: '2026-12', BLK: 'Overall', SVG: 'FLARE',   BLK5: 'NF111', AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Lower', },
        { MONTH: '2027-01', BLK: 'Overall', SVG: 'FLARE',   BLK5: 'NF111', AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Tower', },
        { MONTH: '2027-02', BLK: 'Overall', SVG: 'FLARE',   BLK5: 'NF111', AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Tower', },
        ],



    }
  }),
}