import * as d3 from 'd3'

export default {
  methods: {

    call_Overall(selection) {
      // let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // if(clickTimeout) return
        this.action_Overall_to_Area()
        // clickTimeout = true
      }) 
    },
 

    call_Area(selection, _area) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => { 
        if(clickTimeout) return
        
        this.current.area = _area
        this.executeFunction(
          {
            model: this.model_Area,  
            canvas: this.canvas_Area, 
            func: this.draw_Model_Erection,
            data: null
        })
        clickTimeout = true
      })
    },


    call_Erection_Block(selection, _d, _func) {
      let clickTimeout = false
      
      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', .5) // KeyPlan
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', 0) // KeyPlan
      })
      .on('click', () => {
        if(clickTimeout) return

        if (this.current.area == 'Hull') this.current.block = _d.BLK
        else this.current.pe = _d.BLK

        this.current.sn = _d.SN

        this.executeFunction(
          {
            model: this.model_Erection,  
            canvas: this.canvas_Erection, 
            func: _func,
            data: _d
        })
        clickTimeout = true
      })
    },

    
    call_2PE_Block(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // console.log(_d)
        if(clickTimeout) return

        this.current.pe = _d.AREA

        this.current.sn = _d.SN
        
        this.executeFunction(
          {
            model: this.model_2PE,  
            canvas: this.canvas_2PE, 
            func: this.draw_Model_Block,
            data: _d
        })
        clickTimeout = true
      })
    },



    // ---------------------------------------------------------------------------------------------------------
    // Home & Back Button
    call_Home(selection, exec) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(clickTimeout) return
        if (exec.model) this.executeFunction(exec)
          else selection.call(exec)
        clickTimeout = true
      }) 
    },


    call_Back(selection, exec) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(clickTimeout) return
        this.executeFunction(exec)
        clickTimeout = true
      }) 
    },

    

    // ---------------------------------------------------------------------------------------------------------
    executeFunction(_obj) {

      _obj.model.transition().duration(500).style('opacity', 0)
      _obj.canvas.transition().duration(500).style('opacity', 0)

      setTimeout(() => { 
        // remove Model & Canvas
        _obj.model.remove()
        _obj.canvas.remove()

        // Call Function
        _obj.func(_obj.data)
      }, 1000)
      
    },

  }
}