
import * as d3 from 'd3'

export default {

  data: () => ({
    dir : [
      {AREA:'NORTH',    FOLDER: 'ND11'},
      {AREA:'CENTRAL',  FOLDER: 'CD11'},
      {AREA:'SOUTH',    FOLDER: 'SD11'},
      {AREA:'FLARE',    FOLDER: 'NF11'},
      {AREA:'LQ',       FOLDER: 'LD11'},
    ],

    pop           : {
      width: 1100,
      height: 700,
      x: 250,
      y: 150,
      bar: 32,
      moveX: 0,
      moveY: 0,
      deltaX: 0,
      deltaY: 0,
    },
  }),


  methods: {

    draw_Popup_Window(data_) {

      this.canvas_Popup = this.svg
      .append('svg')
      .attr('width', 1100).attr('height', 700)
   
      .append('g')
      .attr('id', `POPUP`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')
      .attr('opacity', 0).attr('visibility', 'hidden')
     
      // Background - Close
      this.canvas_Popup
      .append('rect')
      .attr('x', -.5).attr('y', -.5).attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight).attr('fill', '#000').attr('opacity', .7)
      .on('click', () => {
        this.close_Popup()
      }) 

      let p = this.pop
      let scrollY = p.y
      let this_ = this

      let modal = this.canvas_Popup
      .append('g')
      .attr('id', 'MODAL_POPUP')
      .attr('transform', `translate(${this.pop.moveX},${this.pop.moveY})`)
      .call(
        d3.drag()
        .on('start', function() {
          if (d3.event.y >= p.y + p.moveY && d3.event.y <=  p.y + p.moveY + p.bar) {
            let coordinate = this_.getCoordinates(d3.select(this))
            p.deltaX = coordinate[0] - d3.event.x
            p.deltaY = coordinate[1] - d3.event.y
          } else return
        })
        .on('drag', function() {
          if (d3.event.y >= p.y + p.moveY && d3.event.y <=  p.y + p.moveY + p.bar) {
            p.moveX = d3.event.x + p.deltaX
            p.moveY = d3.event.y + p.deltaY

            p.moveX = Math.max(-p.x, Math.min(this_.Canvas.CanvasWidth - p.width - p.x, p.moveX))
            p.moveY = Math.max(-p.y, Math.min(this_.Canvas.CanvasHeight - p.height - p.y, p.moveY))
            
            modal.attr('transform', `translate(${p.moveX},${p.moveY})`)
          } else return
        })
      )

      // Filter Window
      modal
      .append('rect')
      .attr('x', p.x).attr('y', scrollY)
      .attr('width', p.width).attr('height', p.height) .attr('rx', 2).attr('stroke', '#757575').attr('stroke-width', 1)
      .attr('fill', '#fff').attr('filter', 'url(#dropshadow)').attr('opacity', .5)

      // Window bar
      modal
      .append('rect')
      .attr('x', p.x).attr('y', scrollY).attr('width', p.width).attr('height', p.bar)
      .attr('fill', 'url(#windowBar)')

      // Contents Window 
      modal
      .append('rect')
      .attr('x', p.x).attr('y', scrollY + p.bar).attr('width', p.width).attr('height', p.height - p.bar)
      .attr('fill', 'url(#contentWindow)')

      modal
      .append('line')
      .attr('x1', p.x).attr('x2', p.width)
      .attr('y1', scrollY + p.bar + 1).attr('y2', scrollY + p.bar + 1)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      modal
      .append('line')
      .attr('x1', p.x).attr('x2', p.width)
      .attr('y1', scrollY + p.bar + 2).attr('y2',scrollY + p.bar + 2)
      .attr('stroke', '#fff').attr('stroke-width', 1)

      this.canvas_Popup.transition().duration(500)
      .attr('visibility', 'display').attr('opacity', 1)

      this.draw_Popup_Elements(modal, data_)
    },


    draw_Popup_Elements(selection, data_) {

      let scrollY = (window.scrollY) + this.pop.y

      // Close Button
      let close = selection
      .append('g')
      .attr('id', 'CLOSE')
      .attr('transform', `translate(${this.pop.x+this.pop.width-25}, ${scrollY +8})`)
      .attr('font-family', 'roboto')
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select('#CLOSE').style('opacity', .3)
      })
      .on('mouseout', () => {
        d3.select('#CLOSE').style('opacity', 1)
      })
      .on('click', () => {
        this.close_Popup()
      }) 

      close
      .append('rect')
      .attr('x', 0).attr('t', 0)
      .attr('width', 15).attr('height', 15)
      .attr('fill', '#fff')
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      close
      .append('path')
      .attr('transform', `translate(0, 0) scale(.03)`)
      .attr('fill', '#333')
      .attr('d', `M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z`)
      
      // KEYPLAN
      this.loadSvg(
        selection, {
        x   : this.pop.x,
        y   : scrollY,
        url : `/Visualization/includes/Network_Legneds.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
      })

      // Load SVG file
      let setUrl = ``

      if (data_.AREA == 'HULL') {
        setUrl = `Hull/${data_.BLK}`
      }  if (data_.AREA == 'TOPSIDE') {
        setUrl = `Topside/${this.dir.find(f=> f.AREA == data_.ZONE).FOLDER}/${data_.SVG}`
      } 
      // console.log(setUrl)
      // if (data_.BLK == 'Overall') setUrl = `Topside/${data_.BLK}`
      
      this.loadSvg(
        selection, {
        x   : this.pop.x + 100,
        y   : scrollY + this.pop.bar + 70,
        url : `/Visualization/BlockDivision/${setUrl}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#BLOCK-GROUP`).attr('transform', `translate(0, 0) scale(.85)`)
        // console.log(this.blockColor)
        this.func_exeBlockColoring(selection, this.blockColor, '') // -----> vFunction/block_Coloring.js
      })


      selection
      .append('text')
      .attr('transform', `translate(${this.pop.x + 10}, ${scrollY + 20})`)
      .attr('font-size', 13).attr('fill', '#000')
      .text(`${data_.AREA} > ${data_.ZONE} > ${data_.LEVEL} > ${data_.BLK}`)

      // console.log('BLOCK:' , data_)

      let blk_ = null
      blk_ = (data_.AREA == 'HULL' || data_.BLK == 'Overall')  ? this.blockColor.find(f=> f.STG == 'G_ER' && f.BLK == data_.BLK5) 
                                                  : this.blockColor.find(f=> f.STG == 'PE3' && f.BLK == data_.BLK5)

      // console.log('TRANS:' , blk_)

      this.func_stageProgress( // -----> ### vFunction/stage_Progress.js
        selection,
        {
          NAME: blk_. BLK,
          PLAN: blk_.PLAN,
          ACT: blk_.ACT,
          DELTA: blk_.DEV,
        }, 
        {
          id: 'ER',
          x: this.pop.x + 15,
          y: scrollY + 55,
        },
      ) 

      let filterList = null
      filterList = (data_.AREA == 'HULL' || data_.BLK == 'Overall')  ? this.masterList.filter(f=> f.G_ER == data_.BLK5)
                                                        : this.masterList.filter(f=> f.G_3PE == data_.BLK5)

      this.func_stageBarChart( // -----> ### vFunction/stage_BarChart.js
        selection,
        filterList, 
        {
          id: 'ER',
          x: this.pop.x + 15,
          y: scrollY + 90,
          nameLen: 90,
          tSize: 9,
          barLen: 100,
          barH: 11,
          lineH: 16,
        },
      ) 
    },


    close_Popup() {
      this.canvas_Popup.transition().duration(500).attr('opacity', 0)
      this.canvas_Popup.transition().duration(500).delay(500).attr('visibility', 'hidden')

      setTimeout(() => { 
        this.canvas_Popup.remove()
      }, 500)
    },


  }
}