import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      // Canvas Group
      this.canvasGroup = this.canvasForms
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.areaList.forEach((d,i)=> {

        this.timeline = {
          ...this.timeline,
          day       : [],
          week      : [],
          month     : [],
          year      : [],
          scale     : null,
          length    : d.width,
        }
        this.init_gmx_TimelineValues(this.timeline, 'day')

        // Set Data
        let chtData = this.rawData.filter(f=> f.AREA == d.code)

        this.primary.max = d3.max([d3.max(chtData, c => c[this.ChartStyle[2].code]), d3.max(chtData, c => c[this.ChartStyle[3].code])])
        this.primary.max = this.getMaxBound_v2(this.primary.max)
        this.primary.axis = d3.scaleLinear()
        .domain([0, this.primary.max])
        .range([0, d.height])

        this.secoundary.max = this.getMaxBound_v2(d3.max(chtData, c => c[this.ChartStyle[0].code]))
        this.secoundary.axis = d3.scaleLinear()
        .domain([0, 100])
        .range([d.height, 0])
        
        this.draw_ChartCanvas(d, i) // -----> draw_Elements.js
        this.draw_Timeline(d) // -----> draw_Timeline.js
        this.chart_Controller(chtData, d) // -----> draw_Chart.js
      })

    },

  }
}
           