import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Erection_Canvas() {
      this.canvas_Erection = this.svg
      .append('g')
      .attr('id', `CANVAS_ERECTION`).attr('transform', `translate(0,0)`).attr('font-family', 'roboto')

      this.button_Home_v2(
        this.canvas_Erection, 
        {x: 450, y: 97, scale: 0.8,}, 
        this.call_Home, 
        {
          model: this.model_Erection,  
          canvas: this.canvas_Erection, 
          func: this.draw_Model_Overall,
          data: null
        }
      )

      this.button_Back_v2(
        this.canvas_Erection, 
        {x: 490, y: 97, scale: 0.8,}, 
        this.call_Back,
        {
          model: this.model_Erection,  
          canvas: this.canvas_Erection, 
          func: this.draw_Model_Area,
          data: null
        }
      )


      // Load a Legend SVG
      let KeyplanArea = this.current.area
      if(this.current.area == 'LQ') KeyplanArea = 'Topside'

      this.loadSvg(
        this.canvas_Erection, {
        x: 30, y: 150,
        url : `/Visualization/includes/Keyplan_${KeyplanArea}.svg`
      }).then(() => {
        
        this.keyPlan.forEach(d => {
          d3.select(`#${d}`).style('opacity', 0)
        })
        d3.selectAll("text").style('font-family', 'roboto')

        
      })
      

      // console.log('this.current:', this.current)
      
      

    },
  }
}