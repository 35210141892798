import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Block_Canvas(_d) {
      this.canvas_Block = this.svg
      .append('g')
      .attr('id', `CANVAS_BLOCK`).attr('transform', `translate(0,0)`).attr('font-family', 'roboto')

      this.button_Home_v2(
        this.canvas_Block, 
        {x: 450, y: 97, scale: 0.8,}, 
        this.call_Home, 
        {
          model: this.model_Block,  
          canvas: this.canvas_Block, 
          func: this.draw_Model_Overall,
          data: _d
        }
      )

      if (this.current.area == 'Hull') {
        this.button_Back_v2(
          this.canvas_Block, 
          {x: 490, y: 97, scale: 0.8,}, 
          this.call_Back,
          {model: this.model_Block,  canvas: this.canvas_Block, func: this.back_Model_Erection, data: _d}
        )
      }

      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.button_Back_v2(
          this.canvas_Block, 
          {x: 490, y: 97, scale: 0.8,}, 
          this.call_Back,
          {model: this.model_Block,   canvas: this.canvas_Block, func: this.back_Model_2PE, data: _d}
        )
      }

      // Load a Legend SVG
      let KeyplanArea = this.current.area
      if(this.current.area == 'LQ') KeyplanArea = 'Topside'
      this.loadSvg(
        this.canvas_Block, {
        x: 30, y: 150,
        url : `/Visualization/includes/Keyplan_${KeyplanArea}.svg`
      }).then(() => {

        this.keyPlan.forEach(d => {
          if(_d.KP !== d) d3.select(`#${d}`).style('opacity', 0)
        })
        d3.selectAll("text").style('font-family', 'roboto')
      })


      // this.canvas_Block
      // .append('text')
      // .attr('transform', `translate(30, 400)`)
      // .attr('font-size', 16)
      // .attr('fill', '#000')
      // .text(_d.TITLE)

    },
  }
}