
import * as d3 from 'd3'

export default {

  data: () => ({
    colorRef: [ 'SC_AS', 'FB_AS', 'AS_AF', 'PT_AF', 'P3_AS', 'ER_AS']
  }),


  methods: {

    func_setBlockColoring(list_) {

      list_.forEach(d=> {
        d.color = '#fff'
        this.colorRef.forEach(c=> {
          if (d[c] !== '') d.color = d[c]
        })
      })
     
    },


    func_exeBlockColoring(selection, list_, spare) {

      list_.forEach(d=> {
        selection.select(`#${d.BLK}`).select('rect').style('fill', d.color)
        selection.select(`#${d.BLK}`).select('path').style('fill', d.color)
      })
     
    },


  }
}