import * as d3 from 'd3'
import moment from 'moment'

import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',
    
    canvasForm:     null,
    canvasTimeline: null,
    canvasSkyline:  null,
    canvasExcept:   null,
    canvasDetail:   null,

    rawData:        null,
    area_Data:      null,
    timeline:       {},
    skyline:        {},
    legends:        {},
    detail: {
      visible: 'Y', x: 50, y: 390, onOff: 'ON'
    },

    areaCodes:       [
      {text: 'Overall', status: 'OVERALL',  value: 0},
      {text: 'Hull',    status: 'HULL',     value: 0},
      {text: 'Topside', status: 'TOPSIDE',  value: 0},
      {text: 'LQ', status: 'LQ',  value: 0},
    ],
    stageCodes:     [],

    currentArea:   {},
    currentStage:  {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    setDefaultValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      this.set_StageStyle() // ---> set_Status.js
      this.currentArea = this.areaCodes.find(f=> f.text == 'Overall')
      this.currentStage = this.stageCodes.find(f=> f.title == 'Cutting')
      
      this.area_Data = this.rawData.filter(f => f.EXCEPT == '' && f[`${this.currentStage.col}`] !== '' && (f[`${this.currentStage.ref}`] !== undefined && f[`${this.currentStage.ref}`] !== null))
      let sql1 = this.Queries.SQL1[0] // Timeline
      let json1 = this.localData.JSON1[0] // Skyline

      // Timeline -------------------------------------------------------------------------------
      this.timeline = {
        title           : sql1.TITLE_SUB,
        baseX           : sql1.X,
        baseY           : sql1.Y,
        length          : sql1.LENGTH,
        cutoff          : sql1.CUTOFF,
        refColumn       : this.currentStage.ref, // ***
        refPro          : this.currentStage.pro, // ***
        startDate       : this.currentStage.start,
        endDate         : this.currentStage.end,
        weekEnd         : sql1.WEEKEND,
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : 35,
      }

      // Skyline Properties -------------------------------------------------------------------------------
      
      this.skyline = {
        refColumn     : this.currentStage.col,

        skyBox: {
          width       : this.currentStage.width,
          height      : json1.BOX_HEIGHT,
          gap         : json1.BOX_GAP,
          radius      : json1.BOX_RADIUS,
          tSize       : json1.BOX_TEXT_SIZE,
        },
      }

      this.legends = {
        x             : json1.LEGEND_X,
        y             : json1.LEGEND_Y,
        height        : json1.BAR_HEIGHT,
      }

      // this.stageCodes.forEach((d,i) => {
      //   let getID = d.ref.split('_')[0]
      //   this.detail.schedule.push({
      //     name: d.title,
      //     id: getID,
      //     ps: `${getID}_PS`,
      //     pf: `${getID}_PF`,
      //   })
      // })
      
      this.set_SkylineData() // ---> below
    },



    set_SkylineData() {
      this.init_gmx_SkylineValues_Week2(this.area_Data)

      this.area_Data.forEach((d,i) => {
        let date__  = this.get_gmx_TimelineCDate_Week(d[this.timeline.refColumn])
        d.cDate     = date__.eDate
        d.sDate     = date__.sDate
        d.date      = d3.timeParse('%Y-%m-%d')(d[this.timeline.refColumn])
        d.tSize    = this.skyline.skyBox.tSize

        if(d[`${this.currentStage.pro}`] == 0 || d[`${this.currentStage.pro}`] == '') d.bColor = '#fff'
        else d.bColor = this.currentStage.color

        if(d[`${this.currentStage.pro}`] > 0 && d[`${this.currentStage.pro}`] < 100) d.opacity = .3
        else d.opacity  = 1
      })
    },
    

    
    set_StageStyle() {
      let json2 = this.localData.JSON2[0]
      let arrRef = json2.REF.replace(/\s/g, '').split('/')
      
      arrRef.forEach((d,i)=> {
        // getDate = d3.max(this.rawData, c => c.PLAN_MAX)
        this.stageCodes[i] = {
          title   : json2.TITLE.replace(/\s/g, '').split('/')[i],
          sch     : json2.SCHEDULE.replace(/\s/g, '').split('/')[i],
          jpg     : json2.JPG.replace(/\s/g, '').split('/')[i],
          col     : json2.COLUMN.replace(/\s/g, '').split('/')[i],
          ref     : json2.REF.replace(/\s/g, '').split('/')[i],
          pro     : json2.PRO.replace(/\s/g, '').split('/')[i],
          color   : json2.COLOR.replace(/\s/g, '').split('/')[i],
  
          width   : Number( json2.WIDTH.replace(/\s/g, '').split('/')[i]),
          start   : Number( json2.START.replace(/\s/g, '').split('/')[i]),
          end     : Number( json2.END.replace(/\s/g, '').split('/')[i]),

          id      : d.replace(/\s/g, '').split('_')[0]
        }
      })

      this.stageCodes.forEach((d,i)=> {
        let filterData = this.rawData.filter(f => f.EXCEPT == '' && f[`${d.ref}`] !== undefined)
        let minDate = d3.min(filterData, c => c[`${this.stageCodes[i].ref}`])
        let maxDate = d3.max(filterData, c => c[`${this.stageCodes[i].ref}`])
        d.start = moment(minDate).add(-(d.start*7), 'days').toDate()
        d.end = moment(maxDate).add(d.end*7, 'days').toDate()

        d.total = this.rawData.filter(f => f[`${d.ref}`] !== undefined).length
        d.ongoing = this.rawData.filter(f => f[`${d.pro}`] > 0 && f[`${d.pro}`] < 100).length
        d.completed = this.rawData.filter(f => f[`${d.pro}`] == 100).length
      })
    },



    set_Gradients() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)
    },

  }
}