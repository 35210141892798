import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Block(_d) {

      this.current.stage = 'BLOCK'
      this.current.sn = _d.SN

      this.model_Block = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let progress = this.model_Block
      .append('g')
      .attr('transform', `translate(40, 380)`)

      let blk_ = this.blockColor.find(f=> f.STG == 'PE3' && f.BLK == _d.BLK5)
      if (blk_) {
        this.func_stageProgress( // -----> ### vFunction/stage_Progress.js
          progress,
          {
            NAME: blk_.BLK,
            PLAN: blk_.PLAN,
            ACT: blk_.ACT,
            DELTA: blk_.DEV,
          }, 
          { id: 'BLK', x: 0, y: 0, },
        ) 

        this.func_stageBarChart( // -----> ### vFunction/stage_BarChart.js
          progress,
          this.masterList.filter(f=> f.G_ER == _d.BLK5), 
          {
            id: 'BLK',
            x: 0,     y: 30,      nameLen: 90,
            tSize: 9, barLen: 100,
            barH: 11, lineH: 16,
          },
        )
      }


      let model = this.model_Block
      .append('svg')
      .attr('width', 1100).attr('height', 700)
      
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      if (this.current.area == 'Hull') {
        this.loadSvg(
          model, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/${this.current.area}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(400,350) scale(.8)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.func_exeBlockColoring(model, this.blockColor, '')

          this.draw_Block_Canvas(_d) // ----->
        })
      }
      
      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.loadSvg(
          model, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/Topside/${_d.AREA}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(200,310) scale(.9)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.blockColor.forEach(d=> {
            model.select(`#${d.BLK}`).select('rect').style('fill', d.color)
            model.select(`#${d.BLK}`).select('path').style('fill', d.color)
          })

          this.draw_Block_Canvas(_d) // ----->
        }) 
      }

      // let info = this.model_Block
      // .append('g')
      // .attr('id', `BLOCK_INFO`)
      // .attr('transform', `translate(40,500)`)

      this.set_selectedItems()
    },

  }
}