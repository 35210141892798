
import * as d3 from 'd3'

export default {
  methods: {

    draw_Yard() {

      this.canvas_Yard = this.svg
      .append('g')
      .attr('id', `CANVAS_YARD`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
     
      this.canvas_Form.moveToFront()

      // Load a Legend SVG
      this.loadSvg(
        this.canvas_Yard, {
        x: 0, y: 0,
        url : '/Visualization/Layout_Yard/Yard_Layout.svg'
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#YARD`).attr('transform', `translate(-800,-600) scale(2)`).attr('opacity', 0)
        d3.select(`#YARD`).transition().duration(500).attr('transform', `translate(0,-200) scale(.75)`).attr('opacity', 1)
      })

    },


    

    

   

  }
}