import * as d3 from 'd3'

export default {
  methods: {

    action_Overall_to_Area() {
      d3.select(`#CANVAS_Overall`).transition().duration(500).style("opacity", 0)
      
      setTimeout(() => { 
        this.canvas_Overall.remove()

        d3.select(`#Overall-MASK`).attr('visibility', 'hidden')

        d3.select(`#Hull`)
        .transition().duration(500).attr('transform', `translate(0,150)`)
        d3.select(`#Topside`)
        .transition().duration(500).attr('transform', `translate(0,-100)`)
        d3.select(`#LQ`)
        .transition().duration(500).attr('transform', `translate(150,-200)`)
      }, 500)

      setTimeout(() => { 
        d3.select(`#Topside-MASK`).attr('visibility', 'display')
        d3.select(`#Hull-MASK`).attr('visibility', 'display')
        d3.select(`#LQ-MASK`).attr('visibility', 'display')
        this.draw_Area_Canvas() // ----->
      }, 1200)

      this.current.stage = 'AREA'

      this.set_selectedItems()
    },


    action_Area_to_Overall() {
      this.canvas_Area.transition().duration(500).style('opacity', 0)

      d3.select(`#Topside-MASK`).attr('visibility', 'hidden')
      d3.select(`#Hull-MASK`).attr('visibility', 'hidden')
      d3.select(`#LQ-MASK`).attr('visibility', 'hidden')

      d3.select(`#Hull`)
      .transition().duration(500).delay(500).attr('transform', `translate(0,0)`)
      d3.select(`#Topside`)
      .transition().duration(500).delay(500).attr('transform', `translate(0,0)`)
      d3.select(`#LQ`)
      .transition().duration(500).delay(500).attr('transform', `translate(0,0)`)

      setTimeout(() => { 

        // Remove the AREA's Dashboard
        this.canvas_Area.remove()
        this.draw_Overall_Canvas() // ----->
        d3.select(`#Overall-MASK`).attr('visibility', 'display').attr('cursor', 'pointer')
      }, 1500)

      this.current.stage = 'OVERALL'

      this.set_selectedItems()
    },



  }
}