import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.chart_Router() //-----> Redrawing


      // Structural Overall Progress -----------------------------------------------------------------------------
      let chtData = this.rawData.find(f=> f.AREA == 'Overall' && f.CDATE == this.timeline.cutoff)

      let overall = this.canvasForms
      .append('g')
      .attr('transform', `translate(30, 25)`)

      overall
      .append('text')
      .attr('x', 0) .attr('y', 0).style('font-size', 13).style('fill', '#000')
      .text(`Structural Overall Progress`)

      overall
      .append('text')
      .attr('x', 160) .attr('y', 0).style('font-size', 9).style('fill', '#858585')
      .text(`Cumulative`)

      overall
      .append('text')
      .attr('id', `TXT_`)
      .attr('x', 0) .attr('y', 20).style('font-size', 12).style('fill', '#757575')
      .text('Plan')

      overall
      .append('text')
      .attr('id', `VALUE_`)
      .attr('x', 30) .attr('y', 20).style('font-size', 12).style('fill', '#333')
      .text(chtData.PE_CUM + '%')

      // Delta Value & Icon
      let margin = this.getNodeElValue(`#TXT_`, 'width') + this.getNodeElValue(`#VALUE_`, 'width')
      overall
      .append('text')
      .attr('id', `DEV_`)
      .attr('x', margin + 20) .attr('y', 20).style('font-size', 13)
      .style('fill', this.setDeltaColor(chtData.DE_CUM))
      .text(chtData.DE_CUM + '%')

      overall
      .append('path')
      .attr('transform', `translate(${margin + this.getNodeElValue(`#DEV_`, 'width') + 24}, 11) scale(.75)`)
      .attr('d', this.setDeltaColor(chtData.DE_CUM, 'icon'))
      .attr('fill', this.setDeltaColor(chtData.DE_CUM))
      .attr('stroke-width', .5)

      // Progress bar
      let barLen = (chtData.AC_CUM/100) * 500

      overall
      .append('rect')
      .attr('x', 0).attr('y', 25).attr('rx', 3).attr('ry', 3)
      .attr('width', 500).attr('height', 18).attr('fill', '#F4F5F5')

      overall
      .append('rect')
      .attr('x', 0).attr('y', 25).attr('rx', 3).attr('ry', 3)
      .attr('width', barLen).attr('height', 18).attr('fill', '#83D2F5')

      overall // Actual Progress
      .append('text')
      .attr('x', barLen + 2) .attr('y', 38).style('font-size', 13).style('fill', '#000')
      .text(chtData.AC_CUM + '%')


      // Structural Progress by Area -----------------------------------------------------------------------------
      let areaGroup = this.canvasForms
      .append('g')
      .attr('transform', `translate(70, 105)`)

      areaGroup
      .append('text')
      .attr('x', 0) .attr('y', 0)
      .style('font-size', 12).style('fill', '#333')
      .text(`Structural Progress by Area`)

      areaGroup
      .append('text')
      .attr('x', 160) .attr('y', 0)
      .style('font-size', 9).style('fill', '#858585')
      .text(`Cumulative`)

      this.areaList.filter(f=> f.code !== 'Overall').forEach((d,i)=> {

        chtData = this.rawData.find(f=> f.AREA == d.code && f.CDATE == this.timeline.cutoff)

        barLen = (chtData.AC_CUM/100) * 140
        let area = areaGroup
        .append('g')
        .attr('transform', `translate(${(i*210) + 20}, 0)`)

        area
        .append('image')
        .attr('transform', `translate(0, 10)`)
        .attr('xlink:href', `${this.HOST}/Dashboard/Construction/${d.code}.jpg`)

        area
        .append('text')
        .attr('x', 0) .attr('y', 130).style('font-size', 11).style('fill', '#000')
        .text(d.code)

        area
        .append('rect')
        .attr('x', 0).attr('y', 135).attr('width', 140).attr('height', 12).attr('fill', '#F4F5F5')

        area
        .append('rect')
        .attr('x', 0).attr('y', 135).attr('width', barLen).attr('height', 12).attr('fill', '#83D2F5')

        area // Actual Progress
        .append('text')
        .attr('x', barLen + 2) .attr('y', 145).style('font-size', 11).style('fill', '#000')
        .text(chtData.AC_CUM + '%')

        // Plan Progress
        area
        .append('text')
        .attr('id', `TXT_${i}`)
        .attr('x', 0) .attr('y', 160).style('font-size', 10).style('fill', '#757575')
        .text('Plan')

        area
        .append('text')
        .attr('id', `VALUE_${i}`)
        .attr('x', 25) .attr('y', 160).style('font-size', 10).style('fill', '#333')
        .text(chtData.PE_CUM + '%')

        // Delta Value & Icon
        margin = this.getNodeElValue(`#TXT_${i}`, 'width') + this.getNodeElValue(`#VALUE_${i}`, 'width')
        area
        .append('text')
        .attr('id', `DEV_${i}`)
        .attr('x', margin + 15) .attr('y', 160)
        .style('font-size', 11).style('fill', this.setDeltaColor(chtData.DE_CUM))
        .text(chtData.DE_CUM + '%')

        area
        .append('path')
        .attr('transform', `translate(${margin + this.getNodeElValue(`#DEV_${i}`, 'width') + 18}, ${151}) scale(.7)`)
        .attr('d', this.setDeltaColor(chtData.DE_CUM, 'icon'))
        .attr('fill', this.setDeltaColor(chtData.DE_CUM))
        .attr('stroke-width', .5)

      })
    },

  }
}
           