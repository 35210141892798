import * as d3 from 'd3'

export default {
  methods: {

    call_Overall(selection) {
      selection
      .on('mouseover', () => {
        d3.select(`#Overall-Group`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#Overall-Group`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.action_Overall_to_Area()
      }) 
    },


    call_Area(selection, _area) {
      selection
      .on('mouseover', () => {
        d3.select(`#${_area}-img`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_area}-img`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.currentArea = _area
        d3.select(`#MODEL_AREA`).transition().duration(500).style('opacity', 0)

        d3.select(`#CANVAS_AREA`).transition().duration(500).style('opacity', 0)

        setTimeout(() => { 
          this.model_Area.remove()
          this.canvas_Area.remove()

          this.draw_Model_Block() // ----->
        }, 1000)
      })
    },


    call_Block(selection, _d) {
      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.ZONE}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.ZONE}`).transition().duration(50).style('opacity', 0)
      })
      .on('click', () => {
        
      })
    },


    // Home & Back Button
    call_Home(selection, func) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        selection.call(func)
      }) 
    },

    call_Back(selection, func) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        selection.call(func)
      }) 
    },


  }
}