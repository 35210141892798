
export default {
  data: () => ({

    localData: {
      
      JSON1: [
        {
          // Timeline
          WEEKEND        : 'friday',

          // Chart Properties
          NAME          : 'Plan-Cuml. / Actual-Cuml. / Plan-wk    / Actual-wk ',
          CODE          : 'PE_CUM     / AC_CUM       / PE_WK      / AC_WK     ',
          CHT_TYPE      : 'LINE       / LINE         / BAR        / BAR       ',
          LINE_TYPE     : 'PLAN       / ACT          / PLAN       / ACT       ',
          LINE_SIZE     : '.5         / 1.5          /            / 1.5       ',
          LINE_COLOR    : '#A7A7A7    / #44A9DF      / #bcbcbc    / #83D2F5   ',
          OPACITY       : '           /              / .3         / .9        ',
        },
      ],

      AREA: [
        {text: '', code: 'Overall', x:70,   y:310,   width:630, height:250},
        {text: '', code: 'Hull',    x:830,  y:45.5,  width:500, height:130},
        {text: '', code: 'Topside', x:830,  y:430,   width:500, height:130},
        {text: '', code: 'LQ',      x:830,  y:235.5, width:500, height:130},
      ],




    }
  }),
}