
import * as d3 from 'd3'

export default {

  data: () => ({
    stage: [
      {name: 'Cutting Commenced',   ref: 'FAB',  plan: 'SC_PS', act: 'SC_AS', pro: 'SC_AP', color: '#E0E0DF'},
      {name: 'Fab. Commenced',      ref: 'FAB',  plan: 'FB_PS', act: 'FB_AS', pro: 'FB_AP', color: '#FEED57'},
      {name: 'Assembly 100%',       ref: 'FAB',  plan: 'AS_PF', act: 'AS_AF', pro: 'AS_AP', color: '#83D2F5'},
      {name: 'Block Painting 100%', ref: 'PE1',  plan: 'PT_PF', act: 'PT_AF', pro: 'PT_AP', color: '#F7BACF'},
      {name: 'On Ground Setting',   ref: 'PE3',  plan: 'P3_PS', act: 'P3_AS', pro: 'P3_AP', color: '#CDDC37'},
      {name: 'Final Setting',       ref: 'EREC', plan: 'ER_PS', act: 'ER_AS', pro: 'ER_AP', color: '#8BC248'},
    ]
  }),


  methods: {

    func_stageBarChart(selection, data_, stl) {

      let midText = (stl.barH/2) + 1

      this.stage.forEach(d=> {
        d.total = data_.filter(f=> f[`${d.plan}`] !== undefined).length
        d.actual = data_.filter(f=> f[`${d.act}`] !== undefined).length
        d.pro = (d.actual>0) ? (d.actual/d.total) : 0
        d.remain = d.total - d.actual
      })

      let barChart = selection
      .append('g')
      .attr('transform', `translate(${stl.x + stl.nameLen}, ${stl.y})`)
      .attr('font-family', 'roboto')

      this.stage.forEach((d,i) => {
        // if(d.total == 0) return
        let y = i * stl.lineH

        barChart
        .append('text')
        .attr('transform', `translate(${-stl.nameLen}, ${y + midText})`)
        .attr('font-size', stl.tSize).attr('fill', '#757575').attr('alignment-baseline', 'middle')
        .text(d.name)

        barChart
        .append('rect')
        .attr('x', 0).attr('y', y)
        .attr('width', stl.barLen).attr('height', stl.barH).attr('fill', '#E0E0DF').attr('opacity', .2)

        barChart
        .append('text') // Total text
        .attr('transform', `translate(${stl.barLen + 2}, ${y + midText})`)
        .attr('font-size', stl.tSize).attr('alignment-baseline', 'middle')
        .attr('fill', d.total == 0 ? '#bcbcbc' : '#333')
        .text(d.total)

        barChart
        .append('rect')
        .attr('x', 0).attr('y', y)
        .attr('width', stl.barLen * d.pro).attr('height', stl.barH).attr('fill', d.color)

        barChart // Actual text
        .append('text')
        .attr('x', d.pro < 0.8 ?  stl.barLen * d.pro + 2 :  stl.barLen * d.pro - 2)
        .attr('y', y + midText)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        .attr('font-size', stl.tSize).attr('alignment-baseline', 'middle')
        .attr('fill', d.actual == 0 ? '#bcbcbc' : '#333')
        .text(d.actual)

      })
     
    },


  }
}