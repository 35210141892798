import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      let x_ = 20
      this.stageList.forEach(d => {
        
        let stg = this.canvasForms
        .append('g')
        .attr('id', `STG_${d.id}`)
        .attr('transform', `translate(${x_}, 20)`)
        .attr('opacity', d.id == this.current.stage.id ? 1 : 0.8)
        .attr('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#STG_${d.id}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#STG_${d.id}`).attr('opacity', d == d ? 1 : 0.8) 
        })
        .on('click', ()=> {
          if(this.current.stage.id == d.id) return
          d3.select(`#STG_${this.current.stage.id}`).attr('opacity', 0.8) 
          d3.select(`#STG_text_${this.current.stage.id}`).attr('font-weight', 400).attr('fill', '#333')
          d3.select(`#STG_in_${this.current.stage.id}`).attr('fill', '#fff')

          this.current.stage = this.stageList.find(f=> f.id == d.id)
          d3.select(`#STG_${d.id}`).attr('opacity', 1) 
          d3.select(`#STG_text_${d.id}`).attr('font-weight', 500).attr('fill', '#000')
          d3.select(`#STG_in_${d.id}`).attr('fill', '#F35E90')

          // Remove & Redraw
          this.canvasGroup.remove()
          this.chart_Router() //-----> Redrawing

          this.currentStage = d.id
          this.set_selectedItems()
        })
        
        stg
        .append('circle')
        .attr('id', `STG_Out_${d.id}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 6).attr('stroke', '#F7BACF').attr('stroke-width', 1).attr('fill', '#fff')

        stg
        .append('circle')
        .attr('id', `STG_in_${d.id}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 3).attr('stroke', '#F35E90').attr('stroke-width', 0).
        attr('fill', this.current.stage.id == d.id ? '#F35E90' : '#fff')

        stg
        .append('text')
        .attr('id', `STG_text_${d.id}`)
        .attr('transform', `translate(11, 0.5)`)
        .attr('font-size', 10)
        .attr('font-weight', this.current.stage.id == d.id ? 500 : 400)
        .attr('fill', '#333')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.text)

        x_ += this.getNodeElValue(`#STG_text_${d.id}`, 'width') + 40
      })

      this.chart_Router() //-----> Redrawing
    },

  }
}
           