import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {
    
    draw_Timeline(area_) {    
      
      let week_Timeline = this.timeline.week.filter(f=> f.eDate >=  new Date(this.timeline.startDate))
      let month_Timeline = this.timeline.month.filter(f=> f.eDate >=  new Date(this.timeline.startDate))

      this.timeline.scale = d3.scaleTime()
      .domain([
        week_Timeline[0].sDate, 
        week_Timeline[week_Timeline.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])

      let Timeline = this.canvasChart
      .append('g')
      .attr('class', 'TIMELINES')
      .attr('transform', `translate(${0}, ${area_.height + .5})`)
      .style('font-family', 'roboto')


      // cutoff Week highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

      this.timeline.week.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
          Timeline
          .append('rect')
          .attr('x', this.timeline.scale(d.sDate))
          .attr('y', 0)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
          .attr('height', 3)
          .style('opacity', 0.3)
          .attr('fill', '#F35E90')            
        }
      })

      // Week
      Timeline
      .append('g')
      .selectAll('line')
      .data(week_Timeline) // *** week
      .enter()
      .append('line')
      .attr('x1', (d) => this.timeline.scale(d.eDate))
      .attr('x2', (d) => this.timeline.scale(d.eDate))
      .attr('y1', 0)
      .attr('y2', 3)
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)


      // Month 
      Timeline
      .append('g')
      .selectAll('text')
      .data(month_Timeline) // *** week
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate) + 1).attr('y', 5)
      .style('font-size', 9)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(month_Timeline) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != month_Timeline.length-1) return `M${this.timeline.scale(d.eDate)}, 0 V 9` // *** week
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)
      
      // Year 
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 16)
      .style('font-size', 10)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)
    }, 



  }
}
