
import * as d3 from 'd3'

export default {
  data: () => ({
    selectedItems:    null,
    getItems:         null,
  }),

  methods: {
    set_selectedItems() {
      this.selectedItems = { 
        ...this.selectedItems,
        stage: this.current.stage,
        area:  this.current.area,
        pe:    this.current.pe,
        block: this.current.block,
        sn:    this.current.sn
      }
    },


    draw_PDF() {
      this.current.stage = this.getItems.stage
      this.current.area = this.getItems.area
      this.current.pe = this.getItems.pe
      this.current.block = this.getItems.block
      this.current.sn = this.getItems.sn

      switch(this.current.stage) {
        case 'OVERALL':  
          this.pdf_overall()
          break
        case 'AREA':         
          this.pdf_Area()
          break
        case 'ERECTION':         
          this.pdf_Erection()
          break
        case '2PE':         
          this.pdf_2PE()
          break
        case 'BLOCK':     
          this.pdf_Block()
          break
      }
    },

    
    pdf_overall() {
      this.draw_Model_Overall()
    },
    

    pdf_Area() {
      this.model_Area = this.svg
      .append('g')
      .attr('id', `MODEL_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Area, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Area.svg`
      }).then(() => {

        d3.select(`#Hull`).style('opacity', 0)
        d3.select(`#Topside`).style('opacity', 0)

        d3.select(`#MODEL_AREA`).attr('transform', `translate(0, 0)`)
        
        d3.select(`#Topside-MASK`).attr('visibility', 'hidden').attr('cursor', 'pointer').call(this.call_Area, 'Topside')
        d3.select(`#Hull-MASK`)   .attr('visibility', 'hidden').attr('cursor', 'pointer').call(this.call_Area, 'Hull')
        d3.select(`#LQ-MASK`)   .attr('visibility', 'hidden').attr('cursor', 'pointer').call(this.call_Area, 'LQ')
        d3.select(`#Overall-MASK`).attr('visibility', 'hidden').call(this.call_Overall)
        

        d3.select(`#Hull`)
        .transition().duration(750).attr('transform', `translate(0, 150)`).style('opacity', 1)
        d3.select(`#Topside`)
        .transition().duration(750).attr('transform', `translate(0, -100)`).style('opacity', 1)
        d3.select(`#LQ`)
        .transition().duration(750).attr('transform', `translate(150,-200)`).style('opacity', 1)
        
        d3.select(`#Topside-MASK`).attr('visibility', 'display')
        d3.select(`#Hull-MASK`).attr('visibility', 'display')
        d3.select(`#LQ-MASK`).attr('visibility', 'display')

        this.draw_Area_Canvas() // ----->
      })
    },

    
    pdf_Erection() {
      this.model_Erection = this.svg
      .append('g')
      .attr('id', `MODEL_ERECTION`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Erection, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/${this.current.area}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')

        // ########################################################### HULL ###########################################################
        if (this.current.area == 'Hull') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-700,-1500) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(250,0) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK5}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK5}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#LINE-4TH`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(.7)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1500).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.7)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(750).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(500).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
            d3.select(`#LINE-4TH`).transition().duration(300).delay(750).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK5}`)
            .transition().duration(500).attr('opacity', 1)
            d3.select(`#MASK-${d.BLK5}`)
            .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_Block)
          })
          this.draw_Erection_Canvas() // ----->
        }

        // ########################################################### TOPSIDE ###########################################################
        if (this.current.area == 'Topside') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1500).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(1)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(1000).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`)
            .transition().duration(500).style('opacity', 1)
            d3.select(`#MASK-${d.BLK}`)
            .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_2PE)
          })
          this.draw_Erection_Canvas() // ----->
        }


        // ########################################################### LQ ###########################################################
        if (this.current.area == 'LQ') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(1800).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`)
            .transition().duration(500).style('opacity', 1)
            d3.select(`#MASK-${d.BLK}`)
            .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_2PE)
          })
          this.draw_Erection_Canvas() // ----->
        }

        this.func_exeBlockColoring(this.model_Erection, this.blockColor, '') // -----> vFunction/block_Coloring.js
      })
    },


    pdf_2PE() {
      let _d = this.erectionList.find(f=> f.SN == this.current.sn)
      
      this.model_2PE = this.svg
      .append('g')
      .attr('id', `MODEL_2PE`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_2PE, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/Topside/${this.current.pe}.svg`
      }).then(() => {
        // console.log(`/Visualization/BlockDivision/Topside/${this.current.pe}.svg`)
        d3.selectAll("text").style('font-family', 'roboto')
        
        // FLARE, LH11, LV51, LV52, Lv53
        if (this.current.pe !== 'CD11' && this.current.pe !== 'SD11' && this.current.pe !== 'ND11' &  this.current.pe !== 'LD11') {
          this.draw_2PE_Canvas(_d) // ----->
          return
        }

        d3.select(`#TEXTS`).style('opacity', 0)
        d3.select(`#LINES`).style('opacity', 0)
        d3.select(`#MODEL_2PE`).style('opacity', 0).attr('transform', `translate(0,0) scale(1)`)
        d3.select(`#MODEL_2PE`).transition().duration(1000).style('opacity', 1)

        this.erectionList.filter(f=> f.AREA === this.current.pe).forEach(d => {

          d3.select(`#${d.BLK}`).attr('opacity', 0)
          d3.select(`#MASK-${d.BLK}`).style('opacity', 0).attr('visibility', 'hidden')
         
          d3.select(`#PNG-${d.BLK}`)
          .transition().duration(750).delay(1000).attr('transform', `translate(${d.X2},${d.Y2}) scale(.75)`)
        })

        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
          d3.select(`#PNG-${d.BLK}`)
          .transition().duration(1000).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.75)`)
          d3.select(`#LINES`).transition().duration(300).delay(1000).style('opacity', 1)
        })

        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
          
          d3.select(`#${d.BLK}`).transition().duration(500).style('opacity', 1)
          d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_2PE_Block, d)
        })
        d3.select(`#TEXTS`).transition().duration(500).style('opacity', 1)
        this.draw_2PE_Canvas(_d) // ----->
        
        this.func_exeBlockColoring(this.model_2PE, this.blockColor, '')
      })
    },


    pdf_Block() {
      let _d = this.erectionList.find(f=> f.SN == this.current.sn)
      
      this.model_Block = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let progress = this.model_Block
      .append('g')
      .attr('transform', `translate(40, 380)`)

      let blk_ = this.blockColor.find(f=> f.STG == 'PE3' && f.BLK == _d.BLK5)
      if (blk_) {
        this.func_stageProgress( // -----> ### vFunction/stage_Progress.js
          progress,
          {
            NAME: blk_.BLK,
            PLAN: blk_.PLAN,
            ACT: blk_.ACT,
            DELTA: blk_.DEV,
          }, 
          { id: 'BLK', x: 0, y: 0, },
        ) 

        this.func_stageBarChart( // -----> ### vFunction/stage_BarChart.js
          progress,
          this.masterList.filter(f=> f.G_ER == _d.BLK5), 
          {
            id: 'BLK',
            x: 0,     y: 30,      nameLen: 90,
            tSize: 9, barLen: 100,
            barH: 11, lineH: 16,
          },
        )
      }

      let model = this.model_Block
      .append('svg')
      .attr('width', 1100).attr('height', 700)
      
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      if (this.current.area == 'Hull') {
        this.loadSvg(
          model, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/${this.current.area}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(400,350) scale(.8)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.func_exeBlockColoring(model, this.blockColor, '')

          this.draw_Block_Canvas(_d) // ----->
        })
      }
      
      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.loadSvg(
          model, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/Topside/${_d.AREA}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(200,310) scale(.9)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.blockColor.forEach(d=> {
            model.select(`#${d.BLK}`).select('rect').style('fill', d.color)
            model.select(`#${d.BLK}`).select('path').style('fill', d.color)
          })

          this.draw_Block_Canvas(_d) // ----->
        }) 
      }
    },
    

   

  }
}