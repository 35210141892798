import * as d3 from 'd3'
import moment from 'moment'

export default {
  data: () => ({
    selectedItems:    null,
    getItems:         null,

    modalOpen       : '',
    modalId         : '',
  }),

  methods: {
    set_selectedItems() {
      this.selectedItems = { 
        ...this.selectedItems,
        modalOpen: this.modalOpen,
        modalId: this.modalId
      }
    },

    draw_PDF() {
      if (this.getItems.modalOpen == 'Y') {
        let d = this.Erection_List.find(f => f.ID == this.getItems.modalId)

        this.draw_Popup_Window(d)
      }
    },

    reset_PDF_Data() {
      this.selectedItems = null
      this.modalOpen = ''
      this.modalId = ''
    },
  }
}