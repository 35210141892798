import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import fnc_zMixins              from '../../../vFunctions/zMixins'

import lmx_localData                  from '../primitives/localData'

import lmx_Events                     from './Events'
import lmx_Watching                   from './Watching'


import lmx_LocalValues                from './set_LocalValues'
import lmx_draw_Forms                 from './draw_Forms'
import lmx_draw_Popup                 from './draw_Popup'

import lmx_draw_PDF                   from './draw_PDF'







export default {
  
  mixins: [
    gmx_zMixins, 
    fnc_zMixins,
    lmx_localData,

    // Local Mixins
    lmx_Events,
    lmx_Watching,
    
    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_Popup,

    lmx_draw_PDF,
  ],

}
