import * as d3 from 'd3'
import moment from 'moment'
import icon from '../../../../../includes/primitives/icons'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData:      [],

    canvasForms:  null,
    canvasGroup:  null,
    canvasChart:  null,

    timeline:     {},
    ChartStyle:   [],

    areaList:     null,

    icon:         icon.arrow,
    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      this.rawData.forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })

      this.areaList = this.localData.AREA

      // General Styles
      let s_ = this.localData.JSON1[0]

      this.timeline = {
        cutoff          : this.Queries.SQL1[0].CUTOFF,
        startDate       : this.Queries.SQL1[0].START_DATE,
        endDate         : this.Queries.SQL1[0].END_DATE,
        weekEnd         : s_.WEEKEND,

        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }
      this.rawData = this.rawData.filter(f=> f.CDATE >= this.timeline.startDate && f.CDATE <= this.timeline.endDate)

      // Set properties of line charts & bar charts
      let codes = s_.NAME.replace(/\s/g, '').split('/')
      codes.forEach((d,i)=> {
        this.ChartStyle.push({
          name    : s_.NAME.replace(/\s/g, '').split('/')[i],
          code    : s_.CODE.replace(/\s/g, '').split('/')[i],
          cType   : s_.CHT_TYPE.replace(/\s/g, '').split('/')[i],
          lType   : s_.LINE_TYPE.replace(/\s/g, '').split('/')[i],
          sWidth  : s_.LINE_SIZE.replace(/\s/g, '').split('/').map(Number)[i],
          sColor  : s_.LINE_COLOR.replace(/\s/g, '').split('/')[i],
          opacity : s_.OPACITY.replace(/\s/g, '').split('/').map(Number)[i],
        })
      })
      
    },

    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
 
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },
  }
}