import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {

    call_Step(selection, d_, i_) {
      selection
      .on('mouseover', () => {
        d3.select(`#STEP_${i_}`).transition().duration(100)
        .attr('transform', `translate(0, 3)`)
        .attr('fill', '#44A9DF').attr('font-size', 17)
      })
      .on('mouseout', () => {
        d3.select(`#STEP_${i_}`).transition().duration(100)
        .attr('transform', `translate(0, 0)`)
        .attr('fill', '#44A9DF').attr('font-size', 11)
      })
      .on('click', () => {
        this.action_Step(d_, i_) // <=== below
      }) 
    },

    
    action_Step(d_, i_) {

      let before = this.selectedStep
      if (this.selectedStep == i_) return
      
      let file = this.canvas_SVG
      .append('g')
      .attr('id', `SVG_FILE_${i_}`)
      .attr('transform', `translate(0,0)`).attr('opacity', 0)
      
      this.loadSvg(
        file, {
        x   : 50,
        y   : 100,
        url : `/Visualization/ErectionTimeline/${d_.CDATE.substr(0,7)}.svg`
      }).then(() => {
        
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#SVG_FILE_${i_}`).transition().duration(1000).attr('opacity', 1)

        setTimeout(() => { 
          d3.select(`#SVG_FILE_${before}`).remove()
          
          this.call_SVG(d_.CDATE.substr(0,7))
        }, 1000)
      })
      

      // Current Step
      d3.select(`#IN_CIRCLE_${this.selectedStep}`).transition().duration(300)
      .attr('fill', '#fff')
      .attr('stroke', '#bcbcbc')

      d3.select(`#OUT_CIRCLE_${this.selectedStep}`).transition().duration(300)
      .attr('r', 0)

      // Selected Step
      d3.select(`#LINE`).transition().duration(300).delay(200)
      .attr('x2', this.timeline.scale(moment(d_.CDATE).toDate()))

      d3.select(`#IN_CIRCLE_${i_}`).transition().duration(200).delay(400)
      .attr('fill', '#83D2F5')
      .attr('stroke', '#83D2F5')

      d3.select(`#OUT_CIRCLE_${i_}`).transition().duration(200).delay(400)
      .attr('r', 20).attr('opacity', 0.3)

      d3.select(`#OUT_CIRCLE_${i_}`).transition().duration(200).delay(600)
      .attr('r', 5).attr('opacity', 1)

      
      this.selectedStep = i_

      this.set_selectedItems()

      d3.select('#BTN_BACK').attr('visibility', (this.selectedStep == 0) ? 'hidden' : 'display')
      d3.select('#BTN_NEXT').attr('visibility', (this.selectedStep == 26) ? 'hidden': 'display')

      
      // Progress bar Animation
      this.codes.forEach((d) => {

        let P_Pro = d_[`${d.code}_EP`]
        let A_Pro = d_[`${d.code}_A`]

        let cP_Pro = this.currentPro[`${d.code}_EP`]
        let cA_Pro = this.currentPro[`${d.code}_A`]

        let barLen = d.bar/100

        let P_Y = 16 + (this.area.barH-((P_Pro/100) * this.area.barH))
        let A_Y = 16 + (this.area.barH-((A_Pro/100) * this.area.barH))
    
        if(d.type == 'OV') {
          // PLAN
          d3.select(`#BAR_${d.code}_EP`).transition().duration(500)
          .attr('width', P_Pro * barLen)

          d3.select(`#POINT_${d.code}_EP`).transition().duration(500)
          .attr('transform', `translate(${P_Pro * barLen + 7}, ${d.y + 26})`)

          d3.select(`#TEXT_${d.code}_EP`).transition().duration(500)
          .attr('width', P_Pro * barLen)
          .attr('x', P_Pro * barLen + 12)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cP_Pro.toFixed(1)), Number(P_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })

          // ACTUAL
          d3.select(`#BAR_${d.code}_A`).transition().duration(500)
          .attr('width', A_Pro * barLen)

          d3.select(`#TEXT_${d.code}_A`).transition().duration(500)
          .attr('width', A_Pro * barLen)
          .attr('x', A_Pro * barLen + 12)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cP_Pro.toFixed(1)), Number(A_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })

        } else {

          // PLAN
          d3.select(`#BAR_${d.code}_EP`).transition().duration(500)
          .attr('y', P_Y)
          .attr('height', (P_Pro/100) * this.area.barH)
          
          d3.select(`#POINT_${d.code}_EP`).transition().duration(500)
          .attr('transform', `translate(${this.area.barW},${P_Y-2}) scale(.7)`)

          d3.select(`#TEXT_${d.code}_EP`).transition().duration(500)
          .attr('y', P_Y+3)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cP_Pro.toFixed(1)), Number(P_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })

          // ACTUAL
          d3.select(`#BAR_${d.code}_A`).transition().duration(500)
          .attr('y', A_Y)
          .attr('height', (A_Pro/100) * this.area.barH)

          d3.select(`#TEXT_${d.code}_A`).transition().duration(500)
          .attr('y', A_Y-2)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cA_Pro.toFixed(1)), Number(A_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })
        }
      })

      this.currentPro = d_
    },
    

    call_SVG(thisMonth) {

      this.yardBlock.filter(f=> f.MONTH == thisMonth).forEach(blk => {

        if(blk.BLK == 'Overall') {
          let block = d3.select(`#${blk.SVG}`)
          .attr('cursor', 'pointer').attr('opacity', 1)
          .on('mouseover', () => {
            block.transition().duration(300).attr('opacity', .3)
          })
          .on('mouseout', () => {
            block.transition().duration(300).attr('opacity', 1)
          })
          .on('click', () => {
            this.draw_Popup_Window(blk) // <=== draw_Popup.js
          }) 

        } else {
          let block = d3.select(`#${blk.SVG}`)
          .attr('cursor', 'pointer').attr('opacity', 1)
          .on('mouseover', () => {
            block.selectAll('rect').transition().duration(300).style('fill', '#B4E3FA')
          })
          .on('mouseout', () => {
            block.selectAll('rect').transition().duration(300).style('fill', '#fff')
          })
          .on('click', () => {
            this.draw_Popup_Window(blk) // <=== draw_Popup.js
          }) 
        }
      })
    },

  }
}