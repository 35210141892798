import * as d3 from 'd3'
import moment from 'moment'

export default {
  data: () => ({
    selectedItems:    null,
    getItems:         null,

    currentStage:     null,
  }),

  methods: {
    set_selectedItems() {
      this.selectedItems = { 
        ...this.selectedItems,
        currentStage: this.currentStage
      }
    },

    draw_PDF() {
      d3.select(`#STG_${this.stageList[0].id}`).attr('opacity', 0.8) 
      d3.select(`#STG_text_${this.stageList[0].id}`).attr('font-weight', 400).attr('fill', '#333')
      d3.select(`#STG_in_${this.stageList[0].id}`).attr('fill', '#fff')

      this.current.stage = this.stageList.find(f=> f.id == this.getItems.currentStage)
      d3.select(`#STG_${this.current.stage.id}`).attr('opacity', 1) 
      d3.select(`#STG_text_${this.current.stage.id}`).attr('font-weight', 500).attr('fill', '#000')
      d3.select(`#STG_in_${this.current.stage.id}`).attr('fill', '#F35E90')

      // Remove & Redraw
      this.canvasGroup.remove()
      this.chart_Router() //-----> Redrawing
    },
  }
}